<template>
    <div style="padding-left: 20px; padding-right: 20px;">
        <n-alert @click="() => showNumbers = !showNumbers" class="cursor-pointer stats-banner">
            <n-space justify="space-between">
                <n-span>
                    <n-icon style="top: 0.2rem;" size="20">
                        <DataTrending24Filled />
                    </n-icon>
                    <n-text strong style="font-weight: 700">&nbsp;Numbers</n-text>
                </n-span>
                <n-button @click.stop="refreshNumbers" text type="info" v-if="showNumbers">
                    <template #icon>
                        <n-icon>
                            <ArrowSync24Filled />
                        </n-icon>
                    </template>
                    Refresh
                </n-button>
            </n-space>
        </n-alert>
    </div>
    <n-collapse-transition :show="showNumbers">
        <n-space style="padding-left: 20px; padding-top: 2rem;">
            <n-card title="Applications">
                <n-space :size="[30, 0]">
                    <n-statistic label="">
                        <template #label>
                            <n-space vertical :size="[0,0]">
                                <n-text depth="3">Submitted</n-text>
                                <n-text depth="3">(Today)</n-text>
                            </n-space>
                        </template>
                        <n-number-animation :from="0" :to="stats.today_applications ?? 0"></n-number-animation>
                    </n-statistic>
                    <n-statistic label="">
                        <template #label>
                            <n-space vertical :size="[0,0]">
                                <n-text depth="3">Total</n-text>
                                <n-text depth="3">Pending</n-text>
                            </n-space>
                        </template>
                        <n-number-animation :from="0" :to="stats.pending_applications ?? 0"
                            class="lala"></n-number-animation>
                    </n-statistic>
                    <n-statistic label="">
                        <template #label>
                            <n-space vertical :size="[0,0]">
                                <n-text depth="3">Total Closed</n-text>
                                <n-text depth="3">(Month)</n-text>
                            </n-space>
                        </template>
                        <n-number-animation :from="0" :to="stats.closed_cases_current_month ?? 0"
                            class="lala"></n-number-animation>
                    </n-statistic>
                </n-space>
            </n-card>
            <n-card title="Revenue">
                <n-space :size="[30, 0]">
                    <n-statistic label="" class="stats-pending-application">
                        <template #label>
                            <n-space vertical :size="[0,0]">
                                <n-text depth="3">Unrealized Revenue</n-text>
                                <n-text depth="3">&nbsp;</n-text>
                            </n-space>
                        </template>
                        $<n-number-animation :from="200"
                            :to="(stats.pending_applications ?? 0) * 200"></n-number-animation>
                    </n-statistic>
                    <n-statistic label="">
                        <template #label>
                            <n-space vertical :size="[0,0]">
                                <n-text depth="3">Labs Booked</n-text>
                                <n-text depth="3">(Today)</n-text>
                            </n-space>
                        </template>
                        <n-number-animation :from="200" :to="stats.lab_tests_booked"></n-number-animation>
                        <n-span>&nbsp;
                            <n-icon v-if="stats.lab_tests_booked > stats.lab_tests_booked_yesterday" size="15"
                                color="green">
                                <ArrowCircleUp32Filled />
                            </n-icon>
                            <n-icon v-if="stats.lab_tests_booked < stats.lab_tests_booked_yesterday" size="15"
                                color="red">
                                <ArrowCircleDown32Filled />
                            </n-icon>
                        </n-span>
                    </n-statistic>
                    <n-statistic label="" class="stats-labs-booked-value">
                        <template #label>
                            <n-space vertical :size="[0,0]">
                                <n-text depth="3">Labs Booked Value</n-text>
                                <n-text depth="3">(Today)</n-text>
                            </n-space>
                        </template>
                        $<n-number-animation :from="200" :to="stats.lab_tests_booked_amount"></n-number-animation>
                        <n-span>&nbsp;
                            <n-icon v-if="stats.lab_tests_booked_amount > stats.lab_tests_booked_amount_yesterday"
                                size="15" color="green">
                                <ArrowCircleUp32Filled />
                            </n-icon>
                            <n-icon v-if="stats.lab_tests_booked_amount < stats.lab_tests_booked_amount_yesterday"
                                size="15" color="red">
                                <ArrowCircleDown32Filled />
                            </n-icon>
                        </n-span>
                    </n-statistic>
                    <n-statistic label="">
                        <template #label>
                            <n-space vertical :size="[0,0]">
                                <n-text depth="3">Labs Booked</n-text>
                                <n-text depth="3">(Month)</n-text>
                            </n-space>
                        </template>
                        <n-number-animation :from="200" :to="stats.lab_tests_booked_month"></n-number-animation>
                    </n-statistic>
                    <n-statistic label="" class="">
                        <template #label>
                            <n-space vertical :size="[0,0]">
                                <n-text depth="3">Labs Booked Value</n-text>
                                <n-text depth="3">(Month)</n-text>
                            </n-space>
                        </template>
                        $<n-number-animation :from="200" :to="stats.lab_tests_booked_amount_month"></n-number-animation>
                    </n-statistic>
                </n-space>
            </n-card>
        </n-space>
    </n-collapse-transition>

    <div style="padding-left: 20px; padding-right: 20px;">
        <n-float-button :right="10" :bottom="10" @click="goToTop" style="z-index: 100">
            <n-icon :size="30">
                <ArrowUp24Filled />
            </n-icon>
        </n-float-button>
        <n-grid cols="7" style="margin-top: 1rem; margin-bottom: 3rem;" x-gap="10" item-responsive>
            <n-gi span="7 600:5">
                <n-space justify="end" style="margin-bottom: 0.25rem;">
                    <n-button size="small" type="primary" class="custom-actions-btn"
                        @click="goToAdhocSection">Adhoc</n-button>
                    <n-button @click="refreshData" text type="info">
                        <template #icon>
                            <n-icon>
                                <ArrowSync24Filled />
                            </n-icon>
                        </template>
                        Refresh
                    </n-button>
                    <n-input placeholder="search" @input="handleSearchInput" clearable>
                        <template #prefix>
                            <n-icon>
                                <Search28Filled />
                            </n-icon>
                        </template>
                    </n-input>
                </n-space>
                <n-list bordered>
                    <n-list-item style="background-color: var(--wheat-background-color);">
                        <n-grid cols="4">
                            <n-gi span="1" style="text-align: left">Name</n-gi>
                            <n-gi span="1" style="text-align: left">Tests</n-gi>
                            <n-gi span="1" style="text-align: left">Paid Amount</n-gi>
                            <n-gi span="1" style="text-align: left">Details</n-gi>
                        </n-grid>
                    </n-list-item>
                </n-list>
                <n-list hoverable bordered
                    style="height: 500px; overflow-y: scroll; border-bottom: 0.1rem solid var(--border-color);">
                    <n-list-item v-for="payment in payments" :key="payment.uuid" class="transaction-list-item"
                        :class="currentTransaction === payment.uuid ? 'selected-row': ''">
                        <n-grid cols="4" class="cursor-pointer" @click="selectTransaction(payment.uuid)" x-gap="10">
                            <n-gi span="1" style="text-align: left">
                                <n-text strong>{{ payment.last_name }}, {{payment.first_name}}</n-text>
                            </n-gi>
                            <n-gi span="1" style="text-align: left">
                                <n-space>
                                    <n-text v-for="test, index in payment.tests">{{index+1}}. {{ test }}</n-text>
                                </n-space>
                            </n-gi>
                            <n-gi span="1" style="text-align: left">
                                <n-space vertical>
                                    <n-text strong type="success">
                                        ${{ payment.amount }}
                                    </n-text>
                                    <n-a @click.stop="" target="_blank" style="color: #6858FB"
                                        :href="'https://dashboard.stripe.com/search?query='+ payment.email">
                                        Stripe&nbsp;
                                        <n-icon style="top: 0.13rem;">
                                            <Open24Filled />
                                        </n-icon>
                                    </n-a>
                                    <n-text class="mini-text">
                                        {{ dayjs(payment.updated_on).format("YYYY-MM-DD hh:mm:ss a") }}
                                    </n-text>
                                </n-space>
                            </n-gi>
                            <n-gi span="1" style="text-align: left">
                                <n-space vertical>
                                    <n-text><b>Email: </b>{{ payment.email }}</n-text>
                                    <n-text><b>Phone: </b>{{ payment.phone }}</n-text>
                                </n-space>
                            </n-gi>
                        </n-grid>
                        <n-card title="Details"
                            v-if="showDetails && !showCustomOrder && currentTransaction && currentTransaction === payment.uuid"
                            closable @close="currentTransaction=null; showDetails = false">
                            <n-space justify="start" style="margin-bottom: 1rem;">
                                <n-text><b>First Name: </b>{{ paymentsMap[currentTransaction].first_name }}</n-text>
                                <n-text><b>Middle Name: </b>{{ paymentsMap[currentTransaction].middle_name }}</n-text>
                                <n-text><b>Last Name: </b>{{ paymentsMap[currentTransaction].last_name }}</n-text>
                                <n-text strong type=""><b>Gender: </b><n-text class="capitalize">{{
                                        paymentsMap[currentTransaction].gender
                                        }}</n-text></n-text>
                            </n-space>
                            <n-space vertical>
                                <n-button size="small" type="info" @click="genLabOrder('chest-x-ray')">Generate X-ray
                                    Order</n-button>
                                <!-- <n-button type="error">Something Else</n-button> -->
                            </n-space>
                        </n-card>
                    </n-list-item>
                </n-list>
                <n-space justify="center" style="margin-top: 1.5rem; margin-bottom: 3rem;">
                    <n-button-group>
                        <n-button :disabled="paidClientPages == 1 || currentPaymentsAPIPage == 1"
                            @click="selectPage(currentPaymentsAPIPage - 1)">Prev</n-button>
                        <n-button v-for="pageIndex in paidClientPages" @click="selectPage(pageIndex)"
                            :disabled="pageIndex == currentPaymentsAPIPage">{{ pageIndex }}</n-button>
                        <n-button :disabled="paidClientPages == 1  || currentPaymentsAPIPage == paidClientPages"
                            @click="selectPage(currentPaymentsAPIPage + 1)">Next</n-button>
                    </n-button-group>
                </n-space>
            </n-gi>
            <n-gi span="7 600:2">
                <n-card title="Adhoc Actions" id="adhoc-actions-section">
                    <n-space vertical>
                        <n-button size="small" secondary type="info" @click="showCustomLabOrder">+ Generate Adhoc
                            Order</n-button>
                        <n-button size="small" secondary type="success" @click="showCustomRecieptView">+ Generate Adhoc
                            Receipt</n-button>
                    </n-space>
                </n-card>
                <!--n-card v-if="!showCustomOrder && !currentTransaction">
                <n-empty description="Please select a transaction"></n-empty>
            </n-card-->
                <n-card v-if="showCustomOrder" title="Generate Custom X-Ray Lab Order" closable
                    @close="showCustomOrder = false; showDetails = false">
                    <n-grid cols="3" y-gap="15">
                        <n-gi span="1">First Name:</n-gi>
                        <n-gi span="2">
                            <n-input type="" v-model:value="customFirstName"></n-input>
                        </n-gi>
                        <n-gi span="1">Last Name:</n-gi>
                        <n-gi span="2">
                            <n-input type="" v-model:value="customLastName"></n-input>
                        </n-gi>
                        <n-gi span="1">Email:</n-gi>
                        <n-gi span="2">
                            <n-input type="email" v-model:value="customEmail"></n-input>
                        </n-gi>
                        <n-gi span="1">DOB:</n-gi>
                        <n-gi span="2">
                            <n-date-picker type="date" v-model:value="customDOB" format="MM/dd/yyyy"></n-date-picker>
                        </n-gi>
                    </n-grid>
                    <n-space justify="center" style="margin-top: 2rem;">
                        <n-button type="primary" @click="generateCustomLabOrder">Generate Lab Order</n-button>
                    </n-space>
                </n-card>
                <n-card v-if="showCustomReceipt" title="Generate Custom Receipt" closable
                    @close="showCustomReceipt = false; showDetails = false;">
                    <n-grid cols="3" y-gap="15">
                        <n-gi span="1">Office:</n-gi>
                        <n-gi span="2">
                            <n-select :options="officeOptions" @click.stop="" clearable v-model:value="customOffice"
                                placeholder="Choose an office">
                            </n-select>
                        </n-gi>
                        <n-gi span="1">First Name:</n-gi>
                        <n-gi span="2">
                            <n-input type="" v-model:value="customFirstName" clearable></n-input>
                        </n-gi>
                        <n-gi span="1">Last Name:</n-gi>
                        <n-gi span="2">
                            <n-input type="" v-model:value="customLastName" clearable></n-input>
                        </n-gi>
                        <n-gi span="1">Appointment Date:</n-gi>
                        <n-gi span="2">
                            <n-date-picker type="date" v-model:value="customAppointmentDate" format="MM/dd/yyyy"
                                clearable></n-date-picker>
                        </n-gi>
                        <n-gi span="1">Email:</n-gi>
                        <n-gi span="2">
                            <n-input type="email" v-model:value="customEmail"></n-input>
                        </n-gi>
                    </n-grid>
                    <n-space justify="center" style="margin-top: 2rem;">
                        <n-button type="primary" @click="generateCustomReceipt">Generate Receipt</n-button>
                    </n-space>
                </n-card>
            </n-gi>
        </n-grid>
    </div>
</template>
<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useStore } from "vuex";

import dayjs from 'dayjs';
import {
    Search28Filled,
    Open24Filled,
    DataTrending24Filled,
    ArrowCircleUp32Filled,
    ArrowCircleDown32Filled,
    ArrowSync24Filled,
    ArrowUp24Filled
} from "@vicons/fluent";

import MiscService from '../../services/misc.service';
import ApplicationService from '../../services/application.service';
import UserService from '../../services/user.service';

const store = useStore();

const stats = ref({});
const payments = ref([]);
const currentTransaction = ref();
const paymentsMap = {};
const showCustomOrder = ref(false);
const showCustomReceipt = ref(false);
let debounceTimer;

const customFirstName = ref("");
const customLastName = ref("");
const customEmail = ref("");
const customDOB = ref();
const customOffice = ref();
const customAppointmentDate = ref();
const showNumbers = ref(false);
const showDetails = ref(false);

const paidClientPages = ref(1);
const currentPaymentsAPIPage = ref(1);

const getPayments = async (pageNumber, searchTerm) => {
    store.dispatch("updateIsLoading", true);
    let response;
    if (searchTerm) {
        response = await MiscService.fetchPayments(1, searchTerm)
    } else {
        response = await MiscService.fetchPayments(currentPaymentsAPIPage.value, null)
    }
    payments.value = response.results
    paidClientPages.value = Math.ceil((response.count / import.meta.env.VITE_API_PAGE_SIZE))

    for (const element of payments.value) {
        paymentsMap[element.uuid] = element;
    }
    store.dispatch("updateIsLoading", false);
}

const getDashboardFeed = async () => {
    store.dispatch("updateIsLoading", true);
    const response = await UserService.getDashboardFeed()
    stats.value = response;
    store.dispatch("updateIsLoading", false);
}

const cinit = async () => {
    getPayments()
    getDashboardFeed()
}

onMounted(() => {
    cinit()
})

const officeOptions = [
    { label: "Dublin", value: "dublin" },
    { label: "Fremont", value: "fremont" },
    { label: "Hayward", value: "hayward" },
    { label: "Modesto", value: "modesto" },
    { label: "Newark", value: "newark" },
    { label: "Redwood City", value: "redwood" },
    { label: "Santa Clara", value: "santaclara" },
    { label: "Stockton", value: "stockton" },
    { label: "Tracy", value: "tracy" },
]

const refreshNumbers = () => {
    getDashboardFeed();
}

const refreshData = () => {
    getPayments();
}

const selectPage = (pageNumber) => {
    currentPaymentsAPIPage.value = pageNumber;
    getPayments(currentPaymentsAPIPage.value);
}

const selectTransaction = (transactionId) => {
    currentTransaction.value = transactionId;
    showCustomOrder.value = false;
    showDetails.value = !showDetails.value;
    customFirstName.value = "";
    customLastName.value = "";
    customEmail.value = "";
    customDOB.value = null;

}

const genLabOrder = async (labOrderType) => {
    store.dispatch("updateIsLoading", true);
    const response = await MiscService.fetchLabOrder(currentTransaction.value, labOrderType)
    const blob = new Blob([response], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `chest_x_ray_${paymentsMap[currentTransaction.value].first_name}_${paymentsMap[currentTransaction.value].last_name}.pdf`);
    document.body.appendChild(link);
    store.dispatch("updateIsLoading", false);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

const triggerSearch = (value) => {
    value = value.toLowerCase()
    // payments.value = payments.value.filter((item) => {
    //     return item.first_name.toLowerCase().includes(value) || item.last_name.toLowerCase().includes(value) || item.middle_name.toLowerCase().includes(value) || item.email.toLowerCase().includes(value) || item.phone.includes(value)
    // })

    getPayments(1, value)
}

const handleSearchInput = (value) => {
    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
        if (value.length >= 3) {
            triggerSearch(value);
        } else {
            getPayments()
        }
    }, 300);
}

const showCustomLabOrder = () => {
    currentTransaction.value = "";
    showCustomOrder.value = true;
    showCustomReceipt.value = false;
}

const showCustomRecieptView = () => {
    currentTransaction.value = "";
    showCustomOrder.value = false;
    showCustomReceipt.value = true;
}

const generateCustomLabOrder = async () => {
    store.dispatch("updateIsLoading", true);
    const response = await MiscService.fetchCustomLabOrder("chest-x-ray", {
        first_name: customFirstName.value,
        last_name: customLastName.value,
        email: customEmail.value,
        dob: dayjs.unix(customDOB.value / 1000).format("MM/DD/YYYY"),
    })
    const blob = new Blob([response], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `chest_x_ray_${customFirstName.value}_${customLastName.value}.pdf`);
    document.body.appendChild(link);
    store.dispatch("updateIsLoading", false);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

const generateCustomReceipt = async () => {
    store.dispatch("updateIsLoading", true);
    const response = await ApplicationService.fetchCustomReceipt({
        templateId: customOffice.value,
        firstName: customFirstName.value,
        middleName: "",
        lastName: customLastName.value,
        appointmentDate: dayjs.unix(customAppointmentDate.value / 1000).format("MM/DD/YYYY"),
        email: customEmail.value
    })

    const blob = new Blob([response], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `appointment_receipt_${customFirstName.value}_${customLastName.value}.pdf`);
    document.body.appendChild(link);
    store.dispatch("updateIsLoading", false);
    link.click();

    customAppointmentDate.value = null;
    customFirstName.value = null;
    customLastName.value = null;
    customOffice.value = null;
    customEmail.value = null;

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

const goToAdhocSection = () => {
    const adhocSection = document.getElementById("adhoc-actions-section");
    adhocSection.scrollIntoView({ behavior: "smooth" });
}

const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
}

</script>
<style lang="css">
.selected-row {
    background-color: var(--wheat-background-color);
}

.n-list .transaction-list-item:hover {
    background-color: var(--wheat-background-color);
}

.stats-pending-application.n-statistic .n-statistic-value .n-statistic-value__content {
    color: rgb(0, 102, 255);
}

.stats-pending-application.n-statistic .n-statistic__label {
    font-weight: 500;
}

.stats-labs-booked-value.n-statistic .n-statistic-value .n-statistic-value__content {
    color: var(--success-color);
}

.stats-banner {
    background-color: transparent;
}
.custom-actions-btn {
    display: none;
}

@media(max-width: 600px) {
    .custom-actions-btn {
        display: block;
    }
}
</style>