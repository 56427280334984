<template>
    <n-card :bordered="false" class="main-card application-edit-card">
        <n-h6 style="text-align: left; color: #117DEC">Edit Details ({{ application.last_name }}, {{
            application.first_name }})</n-h6>
        <n-space style="width:100%; padding-top: 0rem;">
            <n-form-item class="strong-label" label="First Name">
                <n-input v-model:value="editForm.firstName"></n-input>
            </n-form-item>
            <n-form-item class="strong-label" label="Middle Name">
                <n-input v-model:value="editForm.middleName"></n-input>
            </n-form-item>
            <n-form-item class="strong-label" label="Last Name">
                <n-input v-model:value="editForm.lastName"></n-input>
            </n-form-item>
            <n-form-item class="strong-label" label="A Number">
                <n-input v-model:value="editForm.aNumber" :show-button="false" placeholder="Number" class="block-case"
                    @update:value="handleANumberChange" maxlength="9">
                    <template #prefix>A-</template>
                </n-input>
            </n-form-item>
        </n-space>
        <n-divider></n-divider>
        <n-space>
            <n-form-item class="strong-label" label="Quantiferon TB">
                <n-space style="align-items: center;">
                    <n-date-picker clearable
                        v-model:value="editForm.vaccineInformation.qtbCollectionDate"></n-date-picker>
                    <n-radio name="qtb-result" :checked="editForm.vaccineInformation.qtbResult === 'positive'"
                        @change="editForm.vaccineInformation.qtbResult = 'positive'">Positive</n-radio>
                    <n-radio name="qtb-result" :checked="editForm.vaccineInformation.qtbResult === 'negative'"
                        @change="editForm.vaccineInformation.qtbResult = 'negative'">Negative</n-radio>
                    <n-radio name="qtb-result" :checked="editForm.vaccineInformation.qtbResult === 'indeterminate'"
                        @change="editForm.vaccineInformation.qtbResult = 'indeterminate'">Indeterminate</n-radio>
                    <n-form-item label="Chest X-ray Taken" v-if="editForm.vaccineInformation.qtbResult === 'positive'">
                        <n-date-picker v-model:value="editForm.vaccineInformation.qtbChestXrayTaken"></n-date-picker>
                    </n-form-item>
                    <n-form-item label="Chest X-ray Read" v-if="editForm.vaccineInformation.qtbResult === 'positive'">
                        <n-date-picker v-model:value="editForm.vaccineInformation.qtbChestXrayRead"></n-date-picker>
                    </n-form-item>
                    <n-button size="small" secondary @click="clearQuantiferonInfo">
                        <n-text strong>Clear</n-text>
                    </n-button>
                </n-space>
            </n-form-item>
        </n-space>
        <n-divider></n-divider>
        <n-space>
            <n-form-item class="strong-label" label="Syphilis Test">
                <n-select :options="syphilisTestOptions" v-model:value="editForm.vaccineInformation.syphilisTestOption"
                    clearable style="width: 140px;"></n-select>
            </n-form-item>
            <n-form-item class="strong-label" label="RPR Result"
                v-if="editForm.vaccineInformation.syphilisTestOption === 'rpr'">
                <n-radio name="syphilis-test-rpr-result"
                    :checked="editForm.vaccineInformation.syphilisRprPositive === true"
                    @change="editForm.vaccineInformation.syphilisRprPositive = true">Positive</n-radio>
                <n-radio name="syphilis-test-rpr-result"
                    :checked="editForm.vaccineInformation.syphilisRprPositive === false"
                    @change="editForm.vaccineInformation.syphilisRprPositive = false">Negative</n-radio>
            </n-form-item>
            <n-form-item class="strong-label" label="Treponemal Result"
                v-if="editForm.vaccineInformation.syphilisTestOption === 'treponemal'">
                <n-radio name="syphilis-test-treponemal-result"
                    :checked="editForm.vaccineInformation.syphilisTreponemalPositive === true"
                    @change="editForm.vaccineInformation.syphilisTreponemalPositive = true">Positive</n-radio>
                <n-radio name="syphilis-test-treponemal-result"
                    :checked="editForm.vaccineInformation.syphilisTreponemalPositive === false"
                    @change="editForm.vaccineInformation.syphilisTreponemalPositive = false">Negative</n-radio>
            </n-form-item>
            <n-form-item class="strong-label" label="Collection Date"
                v-if="editForm.vaccineInformation.syphilisTestOption === 'rpr'">
                <n-date-picker placeholder="Collection Date" clearable
                    v-model:value="editForm.vaccineInformation.syphilisRprCollectionDate"></n-date-picker>
            </n-form-item>
            <n-form-item class="strong-label" label="Reporting Date"
                v-if="editForm.vaccineInformation.syphilisTestOption === 'rpr'">
                <n-date-picker placeholder="Reporting Date" clearable
                    v-model:value="editForm.vaccineInformation.syphilisRprReportingDate"></n-date-picker>
            </n-form-item>
            <n-form-item class="strong-label" label="Reporting Date"
                v-if="editForm.vaccineInformation.syphilisTestOption === 'treponemal'">
                <n-date-picker placeholder="Reporting Date" clearable
                    v-model:value="editForm.vaccineInformation.syphilisTreponemalReportingDate"></n-date-picker>
            </n-form-item>
        </n-space>
        <n-divider></n-divider>
        <n-space v-if="applicantAge >= 1 && applicantAge <= 24">
            <n-form-item label="Gonorrhea Test (Urine NAA)">
                <n-radio name="gonorrhea-test-result"
                    :checked="editForm.vaccineInformation.gonorrheaTestResult === true"
                    @change="editForm.vaccineInformation.gonorrheaTestResult = true">Positive</n-radio>
                <n-radio name="gonorrhea-test-result"
                    :checked="editForm.vaccineInformation.gonorrheaTestResult === false"
                    @change="editForm.vaccineInformation.gonorrheaTestResult = false">Negative</n-radio>
            </n-form-item>
            <n-form-item class="strong-label" label="Reporting Date">
                <n-date-picker placeholder="Reporting Date"
                    v-model:value="editForm.vaccineInformation.gonorrheaReportingDate"></n-date-picker>
            </n-form-item>
            <n-form-item>
                <n-button size="small" secondary @click="clearGonorrheaInfo">
                    <n-text strong>Clear</n-text>
                </n-button>
            </n-form-item>
        </n-space>
        <n-h5>Vaccine Table</n-h5>
        <n-space justify="end" style="padding-bottom: 0.25rem;">
            <n-button secondary type="warning" size="small" style="font-weight: 500;" @click="clearVaccineForm">
                <template #icon>
                    <n-icon>
                        <ArrowReset24Filled />
                    </n-icon>
                </template>
                Clear Table
            </n-button>
        </n-space>
        <n-space style="max-width: 90vw;">
            <div style="min-width: 1200px; overflow-x: scroll;">
                <n-grid cols="18" item-responsive>
                    <n-gi span="2" class="vaccine-table-heading">Vaccine</n-gi>
                    <n-gi span="2" class="vaccine-table-heading">
                        <n-space vertical>
                            <n-text>Date</n-text>
                            <n-text>(mm/dd/yyyy)</n-text>
                        </n-space>
                    </n-gi>
                    <n-gi span="2" class="vaccine-table-heading">
                        <n-space vertical>
                            <n-text>Date</n-text>
                            <n-text>(mm/dd/yyyy)</n-text>
                        </n-space>
                    </n-gi>
                    <n-gi span="2" class="vaccine-table-heading">
                        <n-space vertical>
                            <n-text>Date</n-text>
                            <n-text>(mm/dd/yyyy)</n-text>
                        </n-space>
                    </n-gi>
                    <n-gi span="2" class="vaccine-table-heading">
                        <n-space vertical>
                            <n-text>Date</n-text>
                            <n-text>(mm/dd/yyyy)</n-text>
                        </n-space>
                    </n-gi>
                    <n-gi span="2" class="vaccine-table-heading">
                        <n-space vertical>
                            <n-text>Date Given by Civil Surgeon</n-text>
                            <n-text>(mm/dd/yyyy)</n-text>
                        </n-space>
                    </n-gi>
                    <n-gi span="2" class="vaccine-table-heading">
                        <n-space vertical justify="center">
                            <n-text>
                                Completed
                            </n-text>
                            <n-text>
                                Mark "X" if
                                complete; write date
                                of lab test if immune
                                or "VH" if varicella
                                history
                            </n-text>
                        </n-space>
                    </n-gi>
                    <n-gi span="1" class="vaccine-table-heading">NA</n-gi>
                    <n-gi span="1" class="vaccine-table-heading">Contradiction</n-gi>
                    <n-gi span="1" class="vaccine-table-heading">Insufficient Time Interval</n-gi>
                    <n-gi span="1" class="vaccine-table-heading">* See Below Table</n-gi>
                </n-grid>
                <n-grid cols="18" item-responsive v-for="vaccineRow, i in vaccineRowNames">
                    <!-- Dtap Row -->
                    <n-gi span="2" class="vaccine-table-data-row-name">
                        <n-space vertical>
                            {{vaccineRow}}
                            <n-space v-if="i === 0">
                                <n-checkbox :checked="editForm.vaccineInformation['v00']"
                                    @update:checked="(value) => editForm.vaccineInformation['v00'] = value">DT</n-checkbox>
                                <n-checkbox :checked="editForm.vaccineInformation['v01']"
                                    @update:checked="(value) => editForm.vaccineInformation['v01'] = value">DTaP</n-checkbox>
                                <n-checkbox :checked="editForm.vaccineInformation['v02']"
                                    @update:checked="(value) => editForm.vaccineInformation['v02'] = value">DTP</n-checkbox>
                            </n-space>
                            <n-space v-if="i === 1">
                                <n-checkbox :checked="editForm.vaccineInformation['v10']"
                                    @update:checked="(value) => editForm.vaccineInformation['v10'] = value">Td</n-checkbox>
                                <n-checkbox :checked="editForm.vaccineInformation['v11']"
                                    @update:checked="(value) => editForm.vaccineInformation['v11'] = value">Tdap</n-checkbox>
                            </n-space>
                            <n-space v-if="i === 2">
                                <n-checkbox :checked="editForm.vaccineInformation['v20']"
                                    @update:checked="(value) => editForm.vaccineInformation['v20'] = value">OPV</n-checkbox>
                                <n-checkbox :checked="editForm.vaccineInformation['v21']"
                                    @update:checked="(value) => editForm.vaccineInformation['v21'] = value">IPV</n-checkbox>
                            </n-space>
                        </n-space>
                    </n-gi>
                    <n-gi span="2" class="vaccine-table-data">
                        <n-input placeholder="mm/dd/yyyy" style="text-align: left;"
                            v-model:value="editForm.vaccineInformation[`${i}0`]"
                            @input="(value) => delr(`${i}0`, value)">
                        </n-input>
                    </n-gi>
                    <n-gi span="2" class="vaccine-table-data">
                        <n-input placeholder="mm/dd/yyyy" style="text-align: left;"
                            v-model:value="editForm.vaccineInformation[`${i}1`]"
                            @input="(value) => delr(`${i}1`, value)">
                        </n-input>
                    </n-gi>
                    <n-gi span="2" class="vaccine-table-data">
                        <n-input placeholder="mm/dd/yyyy" style="text-align: left;"
                            v-model:value="editForm.vaccineInformation[`${i}2`]"
                            @input="(value) => delr(`${i}2`, value)">
                        </n-input>
                    </n-gi>
                    <n-gi span="2" class="vaccine-table-data">
                        <n-input placeholder="mm/dd/yyyy" style="text-align: left;"
                            v-model:value="editForm.vaccineInformation[`${i}3`]"
                            @input="(value) => delr(`${i}3`, value)">
                        </n-input>
                    </n-gi>
                    <n-gi span="2" class="vaccine-table-data">
                        <n-input placeholder="mm/dd/yyyy" style="text-align: left;"
                            v-model:value="editForm.vaccineInformation[`${i}4`]"
                            @input="(value) => delr(`${i}4`, value)">
                        </n-input>
                    </n-gi>
                    <n-gi span="2" class="vaccine-table-data">
                        <n-input type="textarea" style="text-align: left;"
                            v-model:value="editForm.vaccineInformation[`${i}5`]"></n-input>
                    </n-gi>
                    <n-gi span="1" class="vaccine-table-data"><n-checkbox size="small"
                            :checked="editForm.vaccineInformation[`${i}6`]"
                            @update:checked="(value) => editForm.vaccineInformation[`${i}6`] = value"></n-checkbox></n-gi>
                    <n-gi span="1" class="vaccine-table-data"><n-checkbox size="small"
                            :checked="editForm.vaccineInformation[`${i}7`]"
                            @update:checked="(value) => editForm.vaccineInformation[`${i}7`] = value"></n-checkbox></n-gi>
                    <n-gi span="1" class="vaccine-table-data"><n-checkbox size="small"
                            :checked="editForm.vaccineInformation[`${i}8`]"
                            @update:checked="(value) => editForm.vaccineInformation[`${i}8`] = value"></n-checkbox></n-gi>
                    <n-gi span="1" class="vaccine-table-data">
                        <n-checkbox v-if="[8, 12].indexOf(i) !== -1" size="small"
                            :checked="editForm.vaccineInformation[`${i}9`]"
                            @update:checked="(value) => editForm.vaccineInformation[`${i}9`] = value"></n-checkbox>
                        <n-space v-if="[8, 12].indexOf(i) === -1"
                            style="width: 100%; height: 100%; background-color: black;">&nbsp;</n-space>
                    </n-gi>
                </n-grid>
            </div>
        </n-space>
        <n-form-item>
            <n-button secondary type="success" @click="saveApplicantDetails" :loading="isLoading">Save</n-button>
        </n-form-item>
    </n-card>
</template>

<script setup lang="js">
import { h, onMounted, ref } from "vue";
import { useStore } from "vuex";

import VuePdfEmbed from 'vue-pdf-embed'

// optional styles
import 'vue-pdf-embed/dist/styles/annotationLayer.css'
import 'vue-pdf-embed/dist/styles/textLayer.css'

import { Edit24Filled, ArrowReset24Filled } from "@vicons/fluent";
import { useModal } from "naive-ui";
import _ from "lodash";
import dayjs from "dayjs";

import ApplicationService from "@/services/application.service.js";
import { camelToSnakeCase, snakeToCamelCase } from "../../utils";
import EditCustomerErrorModal from "./EditCustomerErrorModal.vue";

const store = useStore();
const modal = useModal();
const props = defineProps([
    "application",
    "getApplications"
])

const isLoading = ref(false);
const hasVaccineFormErrors = ref(false);
const vaccineFormErrors = ref([]);
const vaccineFieldsMarked = [
    // "14",
    // "34",
    // "55",
    // "66",
    // "77",
]
const vaccineRowNames = ref([
    "Dtap",
    "Tdap",
    "Polio",
    "MMR",
    "Hib",
    "Hepatitis B",
    "Varicella",
    "Pneumococcal",
    "Influenza",
    "Rotavirus",
    "Hepatitis A",
    "Meningococcal",
    "COVID-19",
])

let vaccineTableInputs = _.range(0, 13).reduce((acc, i) => {
    _.range(0, 11).forEach(j => {
        if (vaccineFieldsMarked.indexOf(`${i}${j}`) !== -1) {
            acc[`${i}${j}`] = true;
        } else {
            acc[`${i}${j}`] = null;
        }
    });
    return acc;
}, {});

vaccineTableInputs["v00"] = false;
vaccineTableInputs["v01"] = false;
vaccineTableInputs["v02"] = false;
vaccineTableInputs["v10"] = false;
vaccineTableInputs["v11"] = false;
vaccineTableInputs["v20"] = false;
vaccineTableInputs["v21"] = false;

vaccineTableInputs["qtbResult"] = null;
vaccineTableInputs["qtbCollectionDate"] = null;
vaccineTableInputs["syphilisTestOption"] = null;
vaccineTableInputs["syphilisRPRPositive"] = false;
vaccineTableInputs["syphilisTreponemalPositive"] = false;
vaccineTableInputs["syphilisRPRCollectionDate"] = null;
vaccineTableInputs["syphilisReportingDate"] = null;
vaccineTableInputs["gonorrheaTestResult"] = null;
vaccineTableInputs["gonorrheaReportingDate"] = null;

const editForm = ref({
    firstName: null,
    middleName: null,
    lastName: null,
    aNumber: null,
    qtbResult: null,
    qtbCollectionDate: null,
    qtbChestXrayTaken: null,
    qtbChestXrayRead: null,
    syphilisTestOption: null,
    syphilisRprPositive: false,
    syphilisTreponemalPositive: false,
    syphilisTreponemalReportingDate: null,
    syphilisRprCollectionDate: null,
    syphilisRprReportingDate: null,
    gonorrheaTestResult: null,
    gonorrheaReportingDate: null,
    vaccineInformation: { ...vaccineTableInputs }
})

const applicantAge = ref(0);

const syphilisTestOptions = [
    {
        label: "RPR",
        value: "rpr"
    },
    {
        label: "Treponemal",
        value: "treponemal"
    }
]

onMounted(() => {
    if (props.application) {
        editForm.value.firstName = props.application.first_name;
        editForm.value.middleName = props.application.middle_name;
        editForm.value.lastName = props.application.last_name;
        editForm.value.aNumber = props.application.a_number;

        _.forEach(props.application.meta.vaccine_info ?? {}, function (value, key) {
            editForm.value.vaccineInformation[snakeToCamelCase(key)] = value
        });

        applicantAge.value = dayjs().diff(dayjs(props.application.dob), "year");
    }
})

const handleANumberChange = (value) => {
    const sanitizedValue = value ? value.replace(/\D+/g, '') : '';
    editForm.value.aNumber = sanitizedValue;
}

const clearQuantiferonInfo = () => {
    editForm.value.vaccineInformation.qtbCollectionDate = null;
    editForm.value.vaccineInformation.qtbResult = null;
}

const clearGonorrheaInfo = () => {
    editForm.value.vaccineInformation.gonorrheaReportingDate = null;
    editForm.value.vaccineInformation.gonorrheaTestResult = null;
}

const delr = (e, v) => {
    let formattedValue = v.replace(/\D/g, ''); // Remove non-digit characters
    const parts = [];

    // Add MM
    if (formattedValue.length >= 2) {
        parts.push(formattedValue.substr(0, 2));
    } else {
        parts.push(formattedValue);
    }

    // Add DD
    if (formattedValue.length > 2) {
        parts.push(formattedValue.substr(2, 2));
    }

    // Add YYYY
    if (formattedValue.length > 4) {
        parts.push(formattedValue.substr(4, 4));
    }

    // Join parts with '/'
    editForm.value.vaccineInformation[e] = parts.join('/');
};

const clearVaccineForm = () => {
    editForm.value.vaccineInformation = { ...vaccineTableInputs };
}

const validateVaccineForm = () => {
    vaccineFormErrors.value = [];
    if (editForm.value.vaccineInformation.gonorrheaTestResult !== null) {
        if (editForm.value.vaccineInformation.gonorrheaReportingDate === null) {
            vaccineFormErrors.value.push(
                "Gonorrhea Reporting Date is required when Gonorrhea Test Result is selected."
            )
        }
    }
    if (editForm.value.vaccineInformation.syphilisTestOption === "treponemal") {
        if (editForm.value.vaccineInformation.syphilisTreponemalPositive === null) {
            vaccineFormErrors.value.push(
                "Syphilis Test Result is required when Syphilis Test Option is selected as Treponemal."
            )
        }
        if (editForm.value.vaccineInformation.syphilisTreponemalReportingDate === null) {
            vaccineFormErrors.value.push(
                "Syphilis Reporting Date is required when Syphilis Test Result is selected."
            )
        }
    }
    if (editForm.value.vaccineInformation.syphilisTestOption === "rpr") {
        if (editForm.value.vaccineInformation.syphilisRprPositive === null) {
            vaccineFormErrors.value.push(
                "Syphilis Test Result is required when Syphilis Test Option is selected as Treponemal."
            )
        }
        if (editForm.value.vaccineInformation.syphilisRprReportingDate === null) {
            vaccineFormErrors.value.push(
                "Syphilis Reporting Date is required when Syphilis Test Result is selected."
            )
        }
        if (editForm.value.vaccineInformation.syphilisRprCollectionDate === null) {
            vaccineFormErrors.value.push(
                "Syphilis Collection Date is required when Syphilis Test Result is selected."
            )
        }
    }
    if (editForm.value.vaccineInformation.qtbResult !== null) {
        if (editForm.value.vaccineInformation.qtbCollectionDate === null) {
            vaccineFormErrors.value.push(
                "QuantiferonTB Collection Date is required."
            )
        }

        if (editForm.value.vaccineInformation.qtbResult === "positive") {
            if (editForm.value.vaccineInformation.qtbChestXrayRead === null) {
                vaccineFormErrors.value.push(
                    "Chest X-ray Read date is required when QTB Result is selected as Positive."
                )
            }
            if (editForm.value.vaccineInformation.qtbChestXrayTaken === null) {
                vaccineFormErrors.value.push(
                    "Chest X-ray Taken date is required when QTB Result is selected as Positive."
                )
            }
        }
    }

    return vaccineFormErrors.value.length === 0;
}
const saveApplicantDetails = async () => {
    isLoading.value = true;

    if (!validateVaccineForm()) {
        isLoading.value = false;
        modal.create({
            title: "Error(s)",
            preset: "card",
            content: () => h(EditCustomerErrorModal, { errors: vaccineFormErrors.value }),
            style: {
                width: "400px",
            }
        })

        return
    }
    const cleanedVaccineInfo = {};

    _.forEach(editForm.value.vaccineInformation, function (value, key) {
        cleanedVaccineInfo[camelToSnakeCase(key)] = value
    });
    // drop all the keys from editForm vaccineInfo whereever the value is null
    // const filteredVaccineInfo = Object.fromEntries(Object.entries(editForm.value.vaccineInformation).filter
    //     (([, value]) => value !== null));

    const response = await ApplicationService.updateApplicantDetails(props.application.uuid, {
        first_name: editForm.value.firstName,
        middle_name: editForm.value.middleName,
        last_name: editForm.value.lastName,
        a_number: editForm.value.aNumber,
        staff_vaccine_info: cleanedVaccineInfo
    })
    isLoading.value = false;
    if (response.uuid) {
        props.getApplications();
        store.dispatch("updateAppMessage", { msg: "Client data updated.", type: "success" })
    }
}

</script>


<style lang="css">
.application-edit-card .n-form-item .n-form-item-label {
    font-size: 14px;
}
.main-card {
    border: 1px solid #ebebeb;
    margin-top: 1rem;
    width: 100%;
}
.vaccine-table-heading {
    background-color: var(--light-wheat-background-color);
    font-size: 0.7rem;
    font-weight: 700;
    border: 0.1rem solid var(--border-color);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    align-content: center;
}

.vaccine-table-data {
    /* font-size: 0.8rem; */
    border-right: 0.05rem solid var(--border-color);
    border-bottom: 0.05rem solid var(--border-color);
    padding-top: 0rem;
    padding-bottom: 0rem;
    align-content: center;
}

.vaccine-table-data-row-name {
    font-size: 0.8rem;
    font-weight: 500;
    background-color: var(--light-wheat-background-color);
    border-left: 0.05rem solid var(--border-color);
    border-right: 0.05rem solid var(--border-color);
    border-bottom: 0.05rem solid var(--border-color);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    align-content: center;
}

div.vaccine-table-data div.n-input {
    border: none;
    --n-border: none;
}

div.vaccine-table-data div.n-input div.n-input-wrapper {
    border: none;
    --n-border: none;
}
</style>