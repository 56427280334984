<template>
    <n-layout has-sider>
        <n-layout-sider show-trigger collapse-mode="width" :collapsed-width="64" :width="210" :bordered="true"
            :default-collapsed="isMobile()">
            <n-menu :options="menuOptions" class="admin-menu"></n-menu>
        </n-layout-sider>
        <n-layout-content style="min-height: 600px;">
            <component :is="contentComponent"></component>
        </n-layout-content>
    </n-layout>
</template>
<script setup lang="js">
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

// optional styles
import 'vue-pdf-embed/dist/styles/annotationLayer.css'
import 'vue-pdf-embed/dist/styles/textLayer.css'

import { Home24Filled, People24Filled, DocumentBulletListMultiple24Filled } from "@vicons/fluent";

import { h } from "vue";
import { RouterLink } from "vue-router";
import { NIcon } from "naive-ui";
import { isMobile } from "../utils";
import UserService from "@/services/user.service.js";
import AdminDashboard from "../components/admin/AdminDashboard.vue";

const props = defineProps([
    "contentComponent",
])
const store = useStore();

const getProfile = async () => {
    const response = await UserService.getProfile();
    if (response) {
        store.dispatch("updateUser", response)
    }
}

onMounted(() => {
    getProfile();
    const logo = document.querySelector(".logo");
    const header = document.querySelector(".top-header");

    if (logo) {
        logo.style.display = "none";
    }
    if (header) {
        header.style.display = "none";
    }
    if (store.getters.user.a) {
        menuOptions.value.push(
            {
                label: () =>
                    h(
                        RouterLink,
                        {
                            to: {
                                name: 'orders',
                            }
                        },
                        { default: () => 'Orders' }
                    ),
                key: 'go-back-homea',
                icon: renderIcon(DocumentBulletListMultiple24Filled)
            },
        )
    }
})

function renderIcon(icon) {
    return () => h(NIcon, null, { default: () => h(icon) })
}
const menuOptions = ref([
    {
        label: () =>
            h(
                RouterLink,
                {
                    to: {
                        name: 'admin',
                    }
                },
                { default: () => 'Dashboard' }
            ),
        key: 'go-back-home',
        icon: renderIcon(Home24Filled)
    }
])

</script>

<style scoped>
.admin-card .n-button {
    width: 75px;
    text-align: center;
}

.name-column {
    font-size: 600;
    color: #2d5383
}
</style>

