<template>
    <div id="container">
        <div id="map"></div>
        <!-- <div class="overlay" @click="" v-show="showOverlay">CMD/CTRL + Click to interact with the map</div> -->
    </div>
</template>

<script setup lang="js">
import { h, onBeforeUnmount, onMounted, ref } from "vue";
import L from 'leaflet'; // Import Leaflet
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS

import MarkerIcon from '@/assets/images/marker.png';
import { isMobile } from "@/utils";

const map = ref();
const showOverlay = ref(true);

const serviceLocations = ref([
    { id: "sanmateo", name: "San Mateo", coords: [37.5635, -122.3256], address: "3 E 3rd Ave San Mateo CA 94401" },
    { id: "redwood", name: "Redwood City", coords: [37.4868, -122.2264], address: "2000 BROADWAY ST STE 231 Redwood City CA 94063" },
    { id: "santaclara", name: "Santa Clara", coords: [37.3981, -121.9843], address: "4701 Patrick Henry Dr, Bldg 25, St 137 Santa Clara, CA 95054" },
    { id: "sanjose", name: "San Jose", coords: [37.3363, -121.8888], address: "18 South 2nd Street, San Jose, CA 95113" },
    { id: "fremont", name: "Fremont", coords: [37.5537, -121.9824], address: "39039 Paseo Padre Pkwy, STE 208, Fremont, CA 94538" },
    { id: "newark", name: "Newark", coords: [37.5179, -122.0443], address: "8407 Central Avenue  Suite 2013, Newark CA 94560" },
    { id: "oakland", name: "Oakland", coords: [37.8069, -122.2655], address: "1901 Harrison St Suite 1100 Oakland CA 94612" },
    { id: "hayward", name: "Hayward", coords: [37.6532, -122.1051], address: "24301 Southland Dr, Suite 212B Hayward CA 94545" },
    { id: "dublin", name: "Dublin", coords: [37.6992, -121.9440], address: "11501 DUBLIN BLVD STE 211, Dublin CA 94568" },
    { id: "tracy", name: "Tracy", coords: [37.3866, -122.0837], address: "800 W El Camino Real Suite 180 Mountain View CA 94040" },
    { id: "modesto", name: "Modesto", coords: [37.6654, -121.0080], address: "1429 College Ave Suite E Modesto CA 95350" },
    { id: "stockton", name: "Stockton", coords: [37.9614, -121.2993], address: "420 W. Acacia Street Suite 20, Stockton CA 95203" }
])

onMounted(() => {
    var myIcon = L.icon({
        iconUrl: MarkerIcon,
        iconSize: [40, 40],
        iconAnchor: [20, 40],
    });

    map.value = L.map('map', {
        dragging: false,
        scrollWheelZoom: false
    }).setView(isMobile() ? [37.73144589186025, -121.62267729179436] : [37.7397, -121.4252], isMobile() ? 8 : 9);
    map.value.invalidateSize();
    L.tileLayer('https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 21,
        attribution: '&copy; <a href="https://maps.google.com">Google Maps</a> &copy; <a href="https://www.flaticon.com/free-icons/location" title="location icons">Location icons created by Smashicons - Flaticon</a>'
    }).addTo(map.value);


    for (const element of serviceLocations.value) {
        let popupContent = `
        <p style="font-size: 12px;">${element.address}</p>
        <p><button class="popup-btn" id=${element.id}>Direction</button></p>
        `;
        let marker = L.marker(element.coords, { icon: myIcon }).addTo(map.value).bindTooltip(element.name);
        let popup = L.popup(element.coords, { content: popupContent, maxWidth: 120 })
        // marker.bindPopup(`${element.address}`).openPopup()
        marker.on("click", () => {
            popup.openOn(map.value)
        })
    }

    const mapContainer = map.value.getContainer();
    mapContainer.addEventListener("click", (event) => {
        if (event.target && event.target.classList.contains("popup-btn")) {
            const buttonId = event.target.id;
            let bCoords = serviceLocations.value.filter((el) => { return el.id === buttonId })[0].coords;
            let googlemapsurl = `https://www.google.com/maps/dir/?api=1&origin=&destination=${bCoords[0]},${bCoords[1]}&travelmode=driving`
            window.open(googlemapsurl, "_blank")            
        }
    });

    const overlayDiv = document.querySelector(".overlay");
    const mapDiv = document.querySelector("#map");
    // overlayDiv.addEventListener("wheel", toggleOverlay);
    // mapDiv.addEventListener("wheel", disableZoom);
})

onBeforeUnmount(() => {
    // const overlayDiv = document.querySelector(".overlay");
    // overlayDiv.removeEventListener("wheel", toggleOverlay);
})

const toggleOverlay = (event) => {
    event.preventDefault();
    if (event.metaKey || event.ctrlKey) {
        showOverlay.value = !showOverlay.value;
    }
}

const removeOverlay = () => {
    map.value.removeLayer(overlay);
}

</script>

<style lang="css" scoped>
#map {
    width: 1232px;
    height: 470px;
    z-index: 98;
}

.container {
    position: relative;
    /* To position the overlay within the container */
    width: 1232px;
    height: 470px;
    z-index: 98;
}

.overlay {
    position: relative;
    margin-top: -470px;
    width: 1232px;
    height: 470px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* Black with 50% opacity */
    z-index: 99;
    /* Ensures overlay is above content */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    color: #fff;
    font-weight: 600;
}

@media(max-width: 600px) {
    #map {
        width: 100%;
        height: 470px;
    }

    .overlay {
        position: relative;
        margin-top: -470px;
        width: 100%;
        height: 470px;
        background-color: rgba(0, 0, 0, 0.2);
        /* Black with 50% opacity */
        z-index: 99;
        /* Ensures overlay is above content */
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 19px;
        color: #fff;
        font-weight: 600;
    }
}
</style>