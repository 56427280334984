<template>
    <div style="min-height: 800px; padding-top: 3rem; padding-bottom: 3rem">
        <n-h1 class="block-case" style="padding-bottom: 1rem;">
            I-693 Frequently Asked Questions
        </n-h1>
        <n-space vertical class="section-6" style="align-items: center; width: 100%">
            <n-a href="https://youtu.be/fKrnWd7LwwI" class="block-case underline font-20">VIDEO TUTORIAL FOR
                YOUR
                IMMIGRATION EXAM</n-a>
            <br />
            <div style="max-width: 800px;">
                <n-text strong class="font-18">Please EMAIL us at <a
                        href="mailto:support@askforcure.com"><b>support@askforcure.com</b></a> or TEXT us at
                    <b>765-FASTEST
                        (765-327-8378)</b> if you cannot find an answer to your question.</n-text>
            </div>
            <br />
            <br />
            <n-collapse class="faq-section" arrow-placement="right" accordion>
                <n-collapse-item title="What is the cost of having my I-693 examination in your office?" name="1">
                    <n-text>
                        The cost of the immigration medical exam and completing the I-693 form is
                        <b>$200/person<sup>*</sup></b>. We offer the fastest service and best price in entire
                        Bay Area. We do not charge anything extra for completing the I693 form as done by many
                        other places!
                    </n-text>
                    <n-p>
                        <n-text>
                            *This fee does not include any lab tests or imaging or treatment. We also offer the
                            <b>cheapest price for the labs</b> through our exclusive lab partners.
                        </n-text>
                    </n-p>
                </n-collapse-item>
                <n-collapse-item title="Do you have any appointment available Today or Tomorrow?" name="2">
                    <n-p>
                        We have appointment availability everyday <b>but you should complete your labs and
                            vaccine prior to coming to your appointment.</b>
                    </n-p>
                    <n-p>
                        This way you will not need to come to our office multiple times and you can complete
                        your I693 in one go in the fastest time possible.
                    </n-p>
                    <n-p>
                        <n-a class="underline" :href="requiredDocumentURL">All
                            required labs and vaccine are listed above that you can get done prior to your
                            appointment!</n-a>
                    </n-p>
                </n-collapse-item>
                <n-collapse-item title="How long does the exam take and when do I receive my sealed I-693 form?"
                    name="3">
                    <n-p>
                        If all records are available at the time of appointment and no further work-up is
                        needed, we can give you the signed I-693 form sealed in an envelope SAME DAY in about 20
                        minutes!
                    </n-p>
                </n-collapse-item>
                <n-collapse-item title="What payment methods do you accept?" name="4">
                    <n-p>
                        We accept <b>CASH for $200 PRICE for I693</b> at the time of appointment. <i>Any other
                            mode of payment will incur an additional $20 surcharge per applicant.</i>
                    </n-p>
                    <n-p>
                        For lab orders through us, if requested, payment is due electronically via payment link
                        that will be sent to you or can be found below in FAQ for lab tests! <b><i>Best prices
                                in the whole Bay Area!</i></b>
                    </n-p>
                </n-collapse-item>
                <n-collapse-item title="What if I got all the required vaccines in the past but I don't have records?"
                    name="5">
                    <n-p>
                        For <b>Polio, Tetanus(Tdap)</b> and <b>COVID-19</b> vaccines, <b>no immunity tests are
                            acceptable</b>. Hence, if you don't have records for these, you will have to get one
                        dose of these vaccines for your I693 completion.
                    </n-p>
                    <n-p>
                        For the <b>MMR, chickenpox(Varicella)</b> and <b>Hepatitis B; immunity tests done within
                            last 1 year</b> are acceptable alternatives. We offer order slip for the immunity
                        tests at LabCorp at the best possible price:
                        <ul>
                            <li><b>MMR immunity test - </b>Needed for everyone born in or after 1957 who doesn't
                                have proof of vaccination</li>
                            <li><b>Varicella immunity test - </b>Needed for everyone who doesn't have proof of
                                vaccination</li>
                            <li><b>Hepatitis B immunity test - </b>Needed for everyone under 60 years of age who
                                doesn’t have proof of vaccination</li>
                        </ul>
                    </n-p>
                    <n-p class="" style="font-weight: 700;">
                        <a :href="'/pre-checkout?lang='+currentLanguage">Order Immunity Tests Now!</a> Price
                        match guarantee!!!
                    </n-p>
                    <n-p>
                        If you’re not immune to MMR/ Hepatitis B/ Varicella on the lab tests, then you will need
                        to get a vaccination for it.
                    </n-p>
                </n-collapse-item>
                <n-collapse-item
                    title="I don't have original vaccination records but can I get a certificate from another doctor stating that I have completed all childhood vaccination?"
                    name="13">
                    <n-p>
                        A statement or certificate from another doctor regarding your childhood vaccinations, in the
                        absence of the original vaccination records, may be considered fraudulent by USCIS. We kindly
                        request that you provide the original vaccination records. If you do not have them, you may
                        either receive an additional dose of the vaccine or provide proof of immunity by lab tests (lab
                        tests for immunity are applicable only for <b>MMR, Hepatitis B and Varicella</b>).
                    </n-p>
                </n-collapse-item>
                <n-collapse-item title="What if I am pregnant?" name="6">
                    <n-p>
                        If you are pregnant, you are not required to take following vaccines for I693:
                        <ul>
                            <li><b>MMR</b></li>
                            <li><b>Varicella</b></li>
                            <li><b>Polio</b></li>
                        </ul>
                    </n-p>
                    <n-p>
                        Hepatitis B vaccine, COVID-19 vaccine and Tetanus vaccine can be safely taken during
                        pregnancy.
                        <br />
                        All the above vaccines can be taken during breastfeeding.
                    </n-p>
                </n-collapse-item>
                <n-collapse-item title="Do you perform the TB test or blood test?" name="7">
                    <n-p>
                        We offer orders for the following tests at LabCorp at the best possible price:
                        <ul>
                            <li><b>Quantiferon TB test - </b>Needed for everyone of age 2 years or above</li>
                            <li><b>Syphilis test - </b>Needed for everyone of age 18 years to 44 years</li>
                            <li><b>Gonorrhea test - </b>Needed for everyone of age 18 years to 24 years</li>
                        </ul>
                    </n-p>
                    <n-p class="" style="font-weight: 700;">
                        <a :href="'/pre-checkout?lang='+currentLanguage">Order TB/ Syphilis/ Gonorrhea Tests
                            Now!</a> Price
                        match guarantee!!!
                    </n-p>
                    <n-p>
                        <ul>
                            <li><b>MMR immunity test - </b>Needed for everyone born in or after 1957 who doesn't
                                have proof of vaccination</li>
                            <li><b>Varicella immunity test - </b>Needed for everyone who doesn't have proof of
                                vaccination</li>
                            <li><b>Hepatitis B immunity test - </b>Needed for everyone under 60 years of age who
                                doesn’t have proof of vaccination</li>
                        </ul>

                        <a :href="'/pre-checkout?lang='+currentLanguage">Order Immunity Tests Now!</a> Price
                        match guarantee!!!
                    </n-p>
                    <n-p>
                        Once any lab test has been purchased through us, you may complete your lab tests at any
                        Labcorp location near you without any extra payment at the lab. We will contact you as
                        soon as we get the results. These lab prices are for people who don’t have or don’t want
                        to use their medical insurance.
                    </n-p>
                </n-collapse-item>
                <n-collapse-item
                    title="Do you accept medical insurance or travel insurance for the immigration physical exam?"
                    name="9">
                    <n-p>
                        No. Immigration medical exams are not covered under any insurance plans but the labs and
                        vaccines may be covered under your insurance depending on your policy.
                    </n-p>
                </n-collapse-item>
                <n-collapse-item title="What if I or my family members do not have medical insurance?" name="10">
                    <n-p>
                        If you do not have insurance, you may go to your local pharmacy or health department
                        <b><a href="https://findahealthcenter.hrsa.gov/">(Find a health center here.)</a></b> to
                        get your vaccinations. You can also order your lab tests yourself online, the links for
                        required tests are provided <b><a :href="requiredDocumentURL">here</a></b>.
                    </n-p>
                    <n-p>
                        <a
                            href="https://acphd-web-media.s3-us-west-2.amazonaws.com/media/clinics/docs/family-justice-center-immunization-clinics-eng-mar2024.pdf">CLICK
                            HERE for Alameda county Vaccinations for uninsured!</a>
                    </n-p>
                    <n-p>
                        <a
                            href="https://publichealth.sccgov.org/services/public-health-pharmacy-travel-and-immunization-clinic">CLICK
                            HERE for Santa Clara county Vaccinations for uninsured!</a>
                    </n-p>
                    <n-p>
                        <a href="https://gettested.cdc.gov/">CLICK HERE for Syphilis/Gonorrhea testing for
                            uninsured at a STD Clinic!</a>
                    </n-p>
                </n-collapse-item>
                <n-collapse-item title="What do I need to bring to my appointment?" name="11">
                    <n-p>
                        Bring one valid government issued <b>photo ID</b> such as driver's license, state ID or
                        passport.
                    </n-p>
                    <n-p>
                        We strongly recommend that you watch our <a
                            :href="embeddedLanguageVideoMap[currentLanguage] ?? embeddedLanguageVideoMap['en']">instructional
                            video here</a>, which explains
                        everything you need to know in 5 minutes.
                        Most Lab tests and vaccines are <a
                            :href="'/appointments?lang='+currentLanguage+'#requirements-check-section'">explained
                            here.</a>
                    </n-p>
                </n-collapse-item>
                <n-collapse-item title="Is a chest x-ray required?" name="12">
                    <n-p>
                        No, not unless you have a positive tuberculosis lab test result.
                    </n-p>
                </n-collapse-item>
                <n-collapse-item title="What if I don't have a ride for coming to the appointment in person?" name="13">
                    <n-p>
                        You can use any ride share company of your choice. Our clients have reported positive feedback
                        from one particular company:
                        <br />
                        <b notranslate><img :src="SawariImage" width="50" height="25"></b>
                        <ul style="margin-top: 0; padding-top: 0">
                            <li>
                                <a href="https://tinyurl.com/sawari-ios" target="_blank">Apple app&nbsp;
                                    <n-icon style="top: 0.2rem;">
                                        <Open24Filled />
                                    </n-icon>
                                </a>
                            </li>
                            <li>
                                <a href="https://tinyurl.com/sawari-android" target="_blank">Android app&nbsp;
                                    <n-icon style="top: 0.2rem;">
                                        <Open24Filled />
                                    </n-icon>
                                </a>
                            </li>
                        </ul>
                    </n-p>
                    <n-p>
                        All applicants must come in person for appointment!
                    </n-p>
                </n-collapse-item>
            </n-collapse>
        </n-space>
    </div>
</template>

<script setup lang="js">
import { watch, ref, computed } from "vue";
import { useStore } from "vuex";
import { embeddedLanguageVideoMap } from "../utils";
import { Open24Filled } from "@vicons/fluent";
import SawariImage from "@/assets/images/sawari.png";

const store = useStore();

const currentLanguage = computed(() => store.getters.currentLanguage)
const requiredDocumentURL = computed(() => `${import.meta.env.VITE_APP_URL}/appointments?lang=${currentLanguage.value}#requirements-check-section`)

</script>

<style lang="css" scoped>
.faq-section {
    text-align: left;
    width: 800px;
}

.faq-section .n-collapse-item__header-main {
    font-size: 20px;
    text-align: left;
}

.faq-section .n-collapse-item__content-inner,
.n-p {
    font-size: 16px;
    text-align: left;
    color: rgb(95, 93, 93);
}
</style>