// src/services/misc.service.ts
import http from './http.service';

const MiscService = {
  async getBusinessReviews() {
    try {
        const response = await http.get('/reviews/g');
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return {};
    }
    
  }, 

  async triggerConversation(data) {
    try {
      const response = await http.post('/notifications/customer/trigger/conversation/', JSON.stringify(data));

      if (response.status === 201) {
          return response.data;
      }
  } catch (error) {
      return {};
  }    
  },

  async createAppointment(data) {
    try {
      const formData = new FormData();
      formData.append("first_name", data.firstName);
      formData.append("middle_name", data.middleName);
      formData.append("last_name", data.lastName);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("location", data.location);
      formData.append("medical_insurance", data.medicalInsurance);
      formData.append("has_primary_care_doctor", data.hasPrimaryCareDoctor);
      formData.append("scheduled_date", data.scheduledDate);
      formData.append("meta", JSON.stringify({
        "applicant_details": data.applicantDetails ?? "",
        "vaccine_records": data.vaccineRecords ?? "",
        "preferred_language": data.preferredLanguage ?? "English",
      })
      );
      
      const files = data.files ?? [];
      files.forEach((file, index) => {
        formData.append(`uploaded_files`, file.file);
      });         

      const response = await http.post("/appointments/", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    } catch (error) {
      console.error('Error creating appointment:', error);
    }
  },

  async getLabProducts() {
    try {
        const response = await http.get('/payments/labs/products/');
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
      return {};
    }
    
  }, 

  async initiatePayment(data) {
    try {
      const response = await http.post("/payments/labs/initiate/", JSON.stringify(data));
      if (response.status === 201) {
        return response.data;
      }
      
    } catch (error) {
      console.error('Error creating appointment:', error);
    }

    return {}
  },

  async createI693Application(data) {
    try {
      const formData = new FormData();
      formData.append("first_name", data.firstName);
      formData.append("middle_name", data.middleName);
      formData.append("last_name", data.lastName);
      formData.append("a_number", data.aNumber);
      formData.append("gender", data.gender);
      formData.append("dob", data.dob);
      formData.append("city_of_birth", data.cityOfBirth);
      formData.append("country_of_birth", data.countryOfBirth);
      formData.append("street_address", data.address);
      formData.append("apartment_number", data.apartmentNumber);
      formData.append("city", data.city);
      formData.append("state", data.state);
      formData.append("zipcode", data.zipcode);
      formData.append("phone", data.phoneNumber);
      formData.append("email", data.email);
      formData.append("photo_id", data.photoIdNumber);
      formData.append("photo_id_type", data.photoIdType);
      
      const files = data.files ?? [];
      files.forEach((file, index) => {
        formData.append(`uploaded_files`, file.file);
      });         

      const response = await http.post("/i693-application/", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (response.status === 201) {
        return response.data;
      }
    } catch (error) {
      console.error('Error creating appointment:', error);
      return {}
    }

    return {}
  },  

  async fetchPayments(pageNumber, searchTerm) {
    try {
      let response;
      if (searchTerm) {
        response = await http.get(`/payments/?page=${pageNumber}&search=${searchTerm}`);
      } else {
        response = await http.get(`/payments/?page=${pageNumber}`);
      }

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching payments:', error);
      return {}
    }
    return {}
  },

  async fetchLabOrder(paymentUUID, labOrderType) {
    try {
      const response = await http.get(`/payments/${paymentUUID}/lab-order?lab_order_type=${labOrderType}`, {responseType: "blob"});
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching payments:', error);
      return {}
    }
    return {}
  },

  async fetchCustomLabOrder(labOrderType, data) {
    try {
      const response = await http.get(`/payments/lab-order?lab_order_type=${labOrderType}&email=${data.email}&last_name=${data.last_name}&first_name=${data.first_name}&dob=${data.dob}`, {responseType: "blob"});
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching payments:', error);
      return {}
    }
    return {}
  }
};

export default MiscService;