<template>
    <n-space justify="center" style="align-items: center; min-height: 450px;">
        <n-card>
            <n-tabs class="card-tabs" default-value="signin" size="large" animated pane-wrapper-style="margin: 0 -4px"
                pane-style="padding-left: 4px; padding-right: 4px; box-sizing: border-box;">
                <n-tab-pane name="signin" tab="Sign in">
                    <n-form class="sign-in-form">
                        <n-form-item-row label="Username">
                            <n-input v-model:value="username" />
                        </n-form-item-row>
                        <n-form-item-row label="Password">
                            <n-input v-model:value="password" type="password" />
                        </n-form-item-row>
                    </n-form>
                    <n-button type="primary" block strong @click="login">
                        Sign In
                    </n-button>
                </n-tab-pane>
            </n-tabs>
        </n-card>
    </n-space>
</template>
<script setup lang="js">
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import AuthService from "../services/auth.service";

const store = useStore();
const router = useRouter();
const route = useRoute();

const username = ref("");
const password = ref("");
const isLoading = ref(false);

const login = async () => {
    isLoading.value = true;
    const response = await AuthService.login({
        username: username.value,
        password: password.value
    })

    if (response.token) {
        store.dispatch("updateLoggedIn", { token: response.token })
        store.dispatch("updateUser", { a: response.a })
        const nextRoute = route.query.next || { name: "admin" };

        router.push(nextRoute);
    } else {
        store.dispatch("updateLoggedIn", { token: null })
    }
    isLoading.value = false;
}
</script>

<style lang="css">
.sign-in-form .n-input__input {
    text-align: left
}
</style>
