// src/services/http.service.ts
import axios from 'axios';
import HttpErrorHandler from './httperror.handler.js';


const http = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(config => {
  // Do something if you want. lalalalala
  if (localStorage.getItem("jToken")) {
    const excludedUrls = ['/auth/login'];
    if (!excludedUrls.some(url => config.url.includes(url))) {
      const token = localStorage.getItem("jToken");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
  }
  return config;
});

http.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      HttpErrorHandler.handle401Error();
    } else if (error.response && error.response.status === 403) {
      HttpErrorHandler.handle403Error();
    } else {
      // window.location.href = "/error?result=500"
    }
    return Promise.reject(error);
  }
);

export default http;
