<template>
    <n-space vertical style="min-height: 800px;">
        <div style="overflow-x: scroll">
            <n-grid cols="6" item-responsive>
                <n-gi span="6">
                    <n-card class="admin-card" :bordered="false" :style="isMobile() ? '': 'min-width: 1200px;'">
                        <div>
                            <n-space justify="end" style="margin-bottom: 0.25rem;">
                                <n-checkbox @update:checked="(value) => isArchivedFlag = value">Archived</n-checkbox>
                                <n-select v-model:value="sortKey" placeholder="--sort by--" :options="sortOptions"
                                    style="width: 130px;"></n-select>
                                <n-input placeholder="search" @input="handleSearchInput" clearable>
                                    <template #prefix>
                                        <n-icon>
                                            <Search28Filled />
                                        </n-icon>
                                    </template>
                                </n-input>
                            </n-space>
                            <!-- Legend -->
                            <!-- <n-space justify="end" style="font-size: 11px">
                                <n-text type="info" strong
                                    style="background: #117DEC; color: white; padding-left: 0.4rem; padding-right: 0.4rem; padding-top: 0.1rem; padding-bottom: 0.1rem; border-radius: 2px;">
                                    R
                                </n-text><n-text>Receipt</n-text>
                            </n-space> -->
                            <n-list hoverable bordered>
                                <n-list-item style="background-color: var(--wheat-background-color);">
                                    <n-grid cols="12" style="text-align: left" x-gap="4" class="header"
                                        v-if="isMobile()">
                                        <n-gi span="9">Name</n-gi>
                                        <n-gi span="3">
                                            Actions
                                        </n-gi>
                                    </n-grid>
                                    <n-grid cols="12" style="text-align: left" x-gap="4" class="header"
                                        v-if="!isMobile()">
                                        <n-gi span="1">
                                            First Name
                                        </n-gi>
                                        <n-gi span="1">
                                            Middle Name
                                        </n-gi>
                                        <n-gi span="1">
                                            Last Name
                                        </n-gi>
                                        <n-gi span="1">
                                            A-Number
                                        </n-gi>
                                        <n-gi span="1">
                                            Files
                                        </n-gi>
                                        <n-gi span="3">
                                            Dates
                                        </n-gi>
                                        <n-gi span="2">
                                            Template
                                        </n-gi>
                                        <n-gi span="2">
                                            Action
                                        </n-gi>
                                    </n-grid>
                                </n-list-item>
                                <n-list-item v-if="showEditView"
                                    style="background-color: #FEF0DC; color: #F3A234; font-weight: bold;">
                                    <n-grid cols="12">
                                        <n-gi span="12" class="text-left">Currently editing <n-text
                                                style="font-style: italic; color: black">{{
                                                currentSelectedApplication.last_name }}, {{
                                                currentSelectedApplication.first_name }}...</n-text></n-gi>
                                    </n-grid>
                                </n-list-item>
                            </n-list>
                        </div>
                        <div style="height: 500px; overflow-y: scroll">
                            <n-list hoverable bordered>
                                <n-list-item v-for="application in applications">
                                    <n-grid cols="12" style="text-align: left; cursor: pointer;" x-gap="4"
                                        v-if="isMobile()"
                                        @click="() => {moreDetails = !moreDetails; currentMoreDetailsApplicationId = application.uuid}"
                                        item-responsive>
                                        <n-gi span="9">
                                            <n-text strong class="name-column">
                                                {{ application.first_name }} <n-text depth="3">{{
                                                    application.middle_name }}</n-text> {{
                                                application.last_name }}
                                            </n-text>
                                            <n-space vertical style="font-size: 12px;">
                                                <n-text strong>Appointment on: <n-text depth="3" strong type="info">{{
                                                        application.meta["appointment_date"] }}</n-text></n-text>
                                                <n-text strong>Submitted on: <n-text depth="3" strong>{{
                                                        dayjs(application.created_on).isSame(dayjs(), 'day') ? "Today":
                                                        dayjs(application.created_on).format('lll')}}</n-text></n-text>
                                            </n-space>
                                        </n-gi>
                                        <n-gi span="3">
                                            <n-space vertical style="width: 100%">
                                                <n-space>
                                                    <n-button size="small" type="warning" class="action-mobile-button"
                                                        v-if="!showEditView || (currentApplication !== application.uuid)"
                                                        @click.stop="toggleShowEditView(application, application.uuid)">
                                                        <template #icon>
                                                            <n-icon>
                                                                <Edit24Filled />
                                                            </n-icon>
                                                        </template>
                                                    </n-button>
                                                    <n-button size="small" type="primary" class="action-mobile-button"
                                                        v-if="showEditView && currentApplication === application.uuid"
                                                        @click.stop="toggleShowEditView(application, application.uuid)">
                                                        <template #icon>
                                                            <n-icon>
                                                                <CancelRound />
                                                            </n-icon>
                                                        </template>
                                                    </n-button>
                                                    <n-popconfirm
                                                        @positive-click="fetchPDF(application.appointmentDate)">
                                                        <template #trigger>
                                                            <n-button size="small" type="primary"
                                                                class="action-mobile-button"
                                                                :disabled="currentApplication !== application.uuid || !templateChosen || !application.appointmentDate">PDF</n-button>
                                                        </template>
                                                        <n-space vertical>

                                                            <n-text strong>Please make sure <b>first name</b>,
                                                                <b>middle name</b>, <b>last name</b> and <b>A-number</b>
                                                                is
                                                                correct
                                                                before generating PDF.</n-text>
                                                            <!-- <n-checkbox v-model="forceRegenerationFlag">Force
                                                                Regeneration</n-checkbox> -->
                                                        </n-space>
                                                    </n-popconfirm>
                                                </n-space>
                                                <n-space>
                                                    <n-button size="small" type="info" class="action-mobile-button"
                                                        :disabled="currentApplication !== application.uuid || !templateChosen || !application.appointmentDate"
                                                        @click.stop="generateReceipt(application.appointmentDate)">
                                                        R
                                                    </n-button>
                                                    <n-tooltip
                                                        v-if="application.is_active && (!showEditView || (currentApplication !== application.uuid))">
                                                        <template #trigger>
                                                            <n-button size="small" type="error"
                                                                class="action-mobile-button"
                                                                @click.stop="archiveApplication(application.uuid)"
                                                                :loading="isLoading">
                                                                <template #icon>
                                                                    <n-icon>
                                                                        <Archive24Filled />
                                                                    </n-icon>
                                                                </template>
                                                            </n-button>
                                                        </template>
                                                        Archive application
                                                    </n-tooltip>
                                                </n-space>
                                            </n-space>
                                        </n-gi>
                                        <n-gi span="12"
                                            v-if="moreDetails && currentMoreDetailsApplicationId === application.uuid"
                                            style="margin-top: 0rem;">
                                            <n-divider></n-divider>
                                            <n-space style="font-size: 12px; padding-bottom: 0.5rem;">
                                                <n-text>A-Number: {{ application.a_number ?? "--"}}</n-text>
                                            </n-space>

                                            <n-space style="text-align: left; font-size: 12px; padding-bottom: 7px">
                                                <n-text strong>Files:</n-text>
                                                <n-a style="text-decoration: underline"
                                                    @click="fetchFile(application.uuid, 'front')">ID
                                                    Front</n-a>
                                                <n-a style="text-decoration: underline"
                                                    @click="fetchFile(application.uuid, 'back')">ID
                                                    Back</n-a>
                                            </n-space>
                                            <n-space>
                                                <n-select :options="templateOptions" @click.stop="" clearable
                                                    style="width: 180px"
                                                    @update:value="(value) => chooseTemplate(value, application.uuid)"
                                                    placeholder="Choose an office">
                                                </n-select>
                                                <n-date-picker v-model:value="application.appointmentDate"
                                                    style="width: 180px" placeholder="Appointment date" clearable
                                                    type="date" @click.stop="" />
                                            </n-space>
                                        </n-gi>
                                    </n-grid>
                                    <n-grid cols="12" style="text-align: left" x-gap="4" v-if="!isMobile()">
                                        <n-gi span="1">
                                            <n-text strong class="name-column">
                                                {{
                                                application.first_name
                                                }}</n-text>
                                        </n-gi>
                                        <n-gi span="1">
                                            <n-text strong class="name-column">
                                                {{
                                                application.middle_name
                                                }}</n-text>
                                        </n-gi>
                                        <n-gi span="1">
                                            <n-text strong class="name-column">
                                                {{
                                                application.last_name
                                                }}</n-text>
                                        </n-gi>
                                        <n-gi span="1">
                                            <n-text strong class="name-column">
                                                {{
                                                application.a_number
                                                }}</n-text>
                                        </n-gi>
                                        <n-gi span="1">
                                            <n-space vertical style="text-align: left">
                                                <n-a style="text-decoration: underline"
                                                    @click="fetchFile(application.uuid, 'front')">ID
                                                    Front</n-a>
                                                <n-a style="text-decoration: underline"
                                                    @click="fetchFile(application.uuid, 'back')">ID
                                                    Back</n-a>
                                                <!-- <n-a style="text-decoration: underline"
                                            @click="fetchFile(application.uuid, 'application_file')">Application
                                            File</n-a> -->
                                            </n-space>
                                        </n-gi>
                                        <n-gi span="3">
                                            <n-space vertical>
                                                <n-text strong>Appointment on: <n-text depth="3" strong type="info">{{
                                                        application.meta["appointment_date"] }}</n-text></n-text>
                                                <n-text strong>Submitted on: <n-text depth="3" strong>{{
                                                        dayjs(application.created_on).format('lll')}}</n-text></n-text>
                                            </n-space>

                                        </n-gi>
                                        <n-gi span="2">
                                            <n-space vertical>
                                                <n-select :options="templateOptions" @click.stop="" clearable
                                                    @update:value="(value) => chooseTemplate(value, application.uuid)"
                                                    placeholder="Choose an office">
                                                </n-select>
                                                <n-date-picker v-model:value="application.appointmentDate"
                                                    placeholder="Appointment date" clearable type="date"
                                                    @click.stop="" />
                                            </n-space>
                                        </n-gi>
                                        <n-gi span="2" class="action-buttons">
                                            <n-space vertical style="width: 100%">
                                                <n-space>
                                                    <n-button size="small" type="warning" class="action-button"
                                                        v-if="!showEditView || (currentApplication !== application.uuid)"
                                                        @click.stop="toggleShowEditView(application, application.uuid)">
                                                        <template #icon>
                                                            <n-icon>
                                                                <Edit24Filled />
                                                            </n-icon>
                                                        </template>
                                                        Edit
                                                    </n-button>
                                                    <n-button size="small" type="primary" class="action-button"
                                                        v-if="showEditView && currentApplication === application.uuid"
                                                        @click.stop="toggleShowEditView(application, application.uuid)">
                                                        <template #icon>
                                                            <n-icon>
                                                                <CancelRound />
                                                            </n-icon>
                                                        </template>
                                                        Close
                                                    </n-button>
                                                    <n-popconfirm
                                                        @positive-click="fetchPDF(application.appointmentDate)">
                                                        <template #trigger>
                                                            <n-button size="small" type="primary" class="action-button"
                                                                :disabled="currentApplication !== application.uuid || !templateChosen || !application.appointmentDate">PDF</n-button>
                                                        </template>
                                                        <n-space vertical>

                                                            <n-text strong>Please make sure <b>first name</b>,
                                                                <b>middle name</b>, <b>last name</b> and <b>A-number</b>
                                                                is
                                                                correct
                                                                before generating PDF.</n-text>
                                                            <!-- <n-checkbox v-model="forceRegenerationFlag">Force
                                                                Regeneration</n-checkbox> -->
                                                        </n-space>
                                                    </n-popconfirm>
                                                </n-space>
                                                <n-space>
                                                    <n-button size="small" type="info" class="action-button"
                                                        :disabled="currentApplication !== application.uuid || !templateChosen || !application.appointmentDate"
                                                        @click.stop="generateReceipt(application.appointmentDate)">
                                                        <template #icon>
                                                            <n-icon>
                                                                <ArrowDownload24Filled />
                                                            </n-icon>
                                                        </template>
                                                        Receipt
                                                    </n-button>
                                                    <n-tooltip
                                                        v-if="application.is_active && (!showEditView || (currentApplication !== application.uuid))">
                                                        <template #trigger>
                                                            <n-button size="small" type="error" class="action-button"
                                                                @click.stop="archiveApplication(application.uuid)"
                                                                :loading="isLoading">
                                                                <template #icon>
                                                                    <n-icon>
                                                                        <Archive24Filled />
                                                                    </n-icon>
                                                                </template>
                                                            </n-button>
                                                        </template>
                                                        Archive application
                                                    </n-tooltip>
                                                </n-space>
                                            </n-space>

                                        </n-gi>
                                    </n-grid>
                                    <EditCustomer v-if="showEditView && currentApplication==application.uuid"
                                        :key="'edit-'+currentApplication" :application="application"
                                        :get-applications="getApplications" />
                                </n-list-item>
                            </n-list>
                        </div>
                        <n-divider></n-divider>
                        <n-space justify="center" :size="[5, 10]">
                            <n-button-group>
                                <n-button :disabled="applicationAPIPages == 1 || currentAPIPage == 1"
                                    @click="selectPage(currentAPIPage - 1)">Prev</n-button>
                                <n-button v-for="pageIndex in applicationAPIPages" @click="selectPage(pageIndex)"
                                    :disabled="pageIndex == currentAPIPage">{{ pageIndex }}</n-button>
                                <n-button :disabled="applicationAPIPages == 1  || currentAPIPage == applicationAPIPages"
                                    @click="selectPage(currentAPIPage + 1)">Next</n-button>
                            </n-button-group>
                        </n-space>
                    </n-card>
                </n-gi>
            </n-grid>
        </div>
    </n-space>
    <n-drawer v-model:show="showPhotoIDArea" :width="isMobile() ? 400 : 800" placement="right">
        <n-drawer-content title="Lala Software Inc">
            <n-text strong>Patience is not the ability to wait, but the ability to keep a straight face while the image
                loads.
                Its getting loaded - wait!</n-text>
            <br />
            <br />
            <VuePdfEmbed annotation-layer text-layer :source="currentImage"
                v-if="currentImage && currentImage.indexOf('.pdf?') !== -1" />
            <n-image :src="currentImage" v-if="currentImage && currentImage.indexOf('.pdf?') === -1" width="400"
                height="300"></n-image>
        </n-drawer-content>
    </n-drawer>
</template>

<script setup lang="js">
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

import VuePdfEmbed from 'vue-pdf-embed'

// optional styles
import 'vue-pdf-embed/dist/styles/annotationLayer.css'
import 'vue-pdf-embed/dist/styles/textLayer.css'

import {
    Edit24Filled,
    Home24Filled,
    People24Filled,
    Search28Filled,
    Archive24Filled,
    ArrowDownload24Filled
} from "@vicons/fluent";
import { CancelRound } from "@vicons/material";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import ApplicationService from "@/services/application.service.js";
import EditCustomer from "@/components/admin/EditCustomer.vue";
import { fileTypeMap, fileTypeVerboseMap } from "@/utils";
import { isMobile } from "../../utils";

const store = useStore();

const moreDetails = ref(false);
const showPhotoIDArea = ref(false);
const currentImage = ref();
const pdfSource = ref();
const templateChosen = ref();
const currentApplication = ref();
const currentSelectedApplication = ref();
const appointmentDate = ref();
const showEditView = ref(false);
const forceRegenerationFlag = ref(false);
let debounceTimer;

const applicationAPIPages = ref(1);
const currentAPIPage = ref(1);
const isLoading = ref(false);
const searchTerm = ref("");
const sortKey = ref("-created_on");
const currentMoreDetailsApplicationId = ref("");

const templateOptions = [
    { label: "Dublin", value: "dublin" },
    { label: "Fremont", value: "fremont" },
    { label: "Hayward", value: "hayward" },
    { label: "Modesto", value: "modesto" },
    { label: "Newark", value: "newark" },
    { label: "Redwood City", value: "redwood" },
    { label: "Santa Clara", value: "santaclara" },
    { label: "Stockton", value: "stockton" },
    { label: "Tracy", value: "tracy" },
]

const applications = ref([]);
const isArchivedFlag = ref(false);
const defaultFilters = computed(() => {
    return {
        is_active: !isArchivedFlag.value,
        ordering: sortKey.value
    }
})

const sortOptions = [
    { label: "Newest", value: "-created_on" },
    { label: "Oldest", value: "created_on" },
    { label: "First Name", value: "first_name" },
]

const getApplications = async () => {
    store.dispatch("updateIsLoading", true);
    let response;
    if (searchTerm.value) {
        response = await ApplicationService.getApplications(1, searchTerm.value, defaultFilters.value)
    } else {
        response = await ApplicationService.getApplications(currentAPIPage.value, null, defaultFilters.value)
    }
    applications.value = response.results;
    applicationAPIPages.value = Math.ceil((response.count / import.meta.env.VITE_API_PAGE_SIZE))

    store.dispatch("updateIsLoading", false);
}
const cinit = async () => {
    getApplications()
}

onMounted(() => {
    dayjs.extend(localizedFormat);
    cinit()
})

const toggleArchiveBox = (value) => {
    isArchivedFlag.value = value;
}

const selectPage = (pageNumber) => {
    currentAPIPage.value = pageNumber;
    getApplications();
}

const chooseTemplate = (value, applicationUUID) => {
    currentApplication.value = applicationUUID;
    templateChosen.value = value
}

const toggleShowEditView = (application, applicationUUID) => {
    currentSelectedApplication.value = application;

    showEditView.value = !showEditView.value;
    if (showEditView.value) {
        currentApplication.value = applicationUUID;
    } else {
        currentApplication.value = null;
    }
}

const triggerSearch = () => {
    getApplications()
}

const handleSearchInput = (value) => {
    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
        if (value.length >= 3) {
            searchTerm.value = value.toLowerCase()
            triggerSearch();
        } else {
            searchTerm.value = "";
            getApplications()
        }
    }, 220);
}

const fetchFile = async (uuid, fileType) => {
    showPhotoIDArea.value = !showPhotoIDArea.value;
    if (fileTypeMap[fileType] !== 3) {
        const response = await ApplicationService.fetchFile(uuid, fileTypeMap[fileType]);
        if (response.url && fileTypeMap[fileType] !== 3) {
            currentImage.value = response.url;
        }
    }

    // else if (response.url && fileTypeMap[fileType] === 3) {
    //     pdfSource.value = response.url;

    //     // Create a temporary anchor element
    //     const anchor = document.createElement('a');
    //     anchor.href = pdfSource.value;

    //     // Set the download attribute to specify the file name
    //     anchor.download = `${uuid}-ApplicationFile.pdf` || 'download';

    //     // Append the anchor to the document body
    //     document.body.appendChild(anchor);

    //     // Programmatically trigger a click event
    //     anchor.click();

    //     // Remove the anchor from the document
    //     document.body.removeChild(anchor);
    // }
}

const fetchPDF = async (appointmentDate) => {
    // console.log(forceRegenerationFlag.value);
    // return
    store.dispatch("updateIsLoading", true);
    appointmentDate = dayjs(appointmentDate).format("MM/DD/YYYY");
    for (const application of applications.value) {
        if (application.uuid === currentApplication.value) {
            application.appointmentDate = null;
        }
    }
    const response = await ApplicationService.fetchApplication(currentApplication.value, templateChosen.value, appointmentDate)
    store.dispatch("updateIsLoading", false);

    if (response.url) {
        pdfSource.value = response.url;

        // Create a temporary anchor element
        const anchor = document.createElement('a');
        anchor.href = pdfSource.value;

        // Set the download attribute to specify the file name
        anchor.download = `${currentApplication.value}-ApplicationFile.pdf` || 'download';

        // Append the anchor to the document body
        document.body.appendChild(anchor);

        // Programmatically trigger a click event
        anchor.click();

        // Remove the anchor from the document
        document.body.removeChild(anchor);
    }
}

const generateReceipt = async (appointmentDate) => {
    store.dispatch("updateIsLoading", true);
    appointmentDate = dayjs(appointmentDate).format("MM/DD/YYYY");
    let firstName = "";
    let lastName = "";

    for (const application of applications.value) {
        if (application.uuid === currentApplication.value) {
            application.appointmentDate = null;
            firstName = application.first_name;
            lastName = application.last_name;
        }
    }

    const response = await ApplicationService.fetchReceipt(currentApplication.value, templateChosen.value, appointmentDate)
    store.dispatch("updateIsLoading", false);
    const blob = new Blob([response], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `appointment_receipt_${firstName}_${lastName}.pdf`);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

const archiveApplication = async (applicationUUID) => {
    isLoading.value = true;
    const response = await ApplicationService.updateApplicantDetails(applicationUUID, {
        is_active: false
    })
    isLoading.value = false;
    if (response.uuid) {
        getApplications();
        // store.dispatch("updateAppMessage", { msg: "Application archived.", type: "success" })
    }
}

watch(isArchivedFlag, (newValue, oldValue) => {
    getApplications();
})

watch(sortKey, (newValue, oldValue) => {
    getApplications();
})
</script>

<style scoped>
.admin-card .n-button {
    /* width: 75px; */
    text-align: center;
}

.action-button.n-button {
    width: 90px;
}

.name-column {
    font-size: 12px;
    font-size: 600;
    color: #a00909
}
.action-mobile-button {
    font-size: 12px;
    width: 40px;
}
</style>