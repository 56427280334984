<template>
    <div :style="backgroundStyle">
    </div>
    <div class="circle-banner block-case fancy-font">
        <!-- <n-space vertical justify="center" style="align-items: center"> -->

        <n-text>
            Staying healthy is a</n-text>
        <n-text>lifelong journey.</n-text>
        <n-text>WE can help.</n-text>

        <n-button round type="primary" style="min-height: 60px; min-width: 227px" @click="goToAppointment">
            <n-space vertical>

                <n-text class="block-case regular-font" style="font-size: 16px; color: white;">Make an</n-text>
                <n-text class="block-case regular-font" style="font-size: 16px; color: white;">Appointment</n-text>
            </n-space>
        </n-button>
        <!-- </n-space> -->
    </div>
    <n-grid cols="1">
        <n-gi style="align-items: center; text-align: center; display: flex; justify-content: center;">
            <n-card style="min-height: 500px; background-color: white; padding-top: 2rem; max-width: 600px;"
                :bordered="false">
                <n-space vertical justify="center">
                    <n-h1 class="fancy-font">ABOUT US</n-h1>
                    <!-- <n-button @click="callModal">Test Modal</n-button> -->
                    <n-text style="font-size: 16px;" class="regular-font">
                        Our mission is to provide you with personalized, high-quality care. We are dedicated to avail
                        you with the fastest and most accurate I693 immigration form completion!
                    </n-text>
                    <br>
                    <n-text style="font-size: 18px">
                        I693 CIVIL Surgeon / OBESITY MEDICINE
                    </n-text>

                    <br>
                    <n-text style="font-size: 16px">
                        Dr. Vinit Agrawal is double board-certified in Internal Medicine and Obesity Medicine. He offers
                        a broad array of services geared to address today’s most common illnesses with a specialized
                        interest in helping patients with their weight management.
                    </n-text>

                    <br>
                    <n-text style="font-size: 18px; font-weight: 900" class="block-case">
                        He is also a USCIS certified Civil Surgeon offering the fastest I693 exam at the best possible
                        price!
                    </n-text>
                </n-space>
            </n-card>
        </n-gi>
        <n-gi style="align-items: center; text-align: center; display: flex; justify-content: center;">
            <n-card style="min-height: 500px; background-color: white; padding-top: 5rem; width: 1200px"
                :bordered="false">
                <n-space vertical justify="center">
                    <n-h1 class="fancy-font block-case" style="font-size: 36px">Serving Entire Bay Area / East Bay /
                        Valley</n-h1>
                    <ServiceLocationMapView></ServiceLocationMapView>
                </n-space>
            </n-card>
        </n-gi>
        <n-gi style="align-items: center; text-align: center; display: flex; justify-content: center;">
            <n-card style="background-color: white; padding-top: 4rem; width: 1200px" :bordered="false">
                <n-space vertical justify="center">
                    <n-h1 class="fancy-font block-case" style="font-size: 24px">IMMIGRATION MEDICAL CENTERS</n-h1>
                    <n-text style="font-size: 18px">
                        We are serving the entire Bay area, East Bay and The Valley across our 11 centers in <b>San
                            Mateo, Redwood City, Santa Clara, San Jose, Fremont/Newark, Oakland, Hayward, Dublin, Tracy,
                            Modesto and Stockton!</b>
                    </n-text>
                </n-space>
            </n-card>
        </n-gi>
        <n-gi style="align-items: center; text-align: center; display: flex; justify-content: center;">
            <n-card style="background-color: white; padding-top: 0rem; width: 1200px" :bordered="false">
                <n-space vertical justify="center">
                    <n-h1 class="fancy-font block-case" style="font-size: 24px">Contact Us</n-h1>
                    <n-space vertical>
                        <n-text style="font-size: 18px;">
                            support@askforcure.com
                        </n-text>
                        <n-text style="font-size: 18px;">
                            Call 765-327-8378
                        </n-text>
                        <n-text style="font-size: 18px;">
                            FAX any documents to (559) 751-3076.
                        </n-text>
                    </n-space>
                </n-space>
            </n-card>
        </n-gi>
        <n-gi style="align-items: center; text-align: center; display: flex; justify-content: center;">
            <n-card style="background-color: white; padding-top: 0rem; width: 1200px" :bordered="false">
                <n-space vertical justify="center">
                    <n-h1 class="fancy-font block-case" style="font-size: 24px">Hours</n-h1>
                    <n-space vertical>
                        <n-text style="font-size: 18px;">
                            We are not limited by the boundaries of fixed hours.
                        </n-text>
                        <n-text style="font-size: 18px;">
                            If you need to schedule, send us a message.
                        </n-text>
                        <n-text style="font-size: 18px;">
                            We will try our best to accommodate you.
                        </n-text>
                    </n-space>
                </n-space>
            </n-card>
        </n-gi>
    </n-grid>
    <Reviews />
</template>

<script setup lang="js">
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";

import L from 'leaflet'; // Import Leaflet
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import MiscService from "../services/misc.service";
import GoogleLogo from "@/assets/images/google-logo.png";
import MainBanner from "@/assets/images/main-banner.png";
// import MainBannerMobile from "@/assets/images/main-banner-mobile.png";
import MainBannerMobile from "@/assets/images/main-banner.png";
import ServiceLocationMapView from '../components/ServiceLocationMapView.vue';
import Reviews from '../components/Reviews.vue';
import { isMobile } from '@/utils';

const store = useStore();
const lang = computed(() => store.getters.currentLanguage);

const init = async () => {

}

onMounted(() => {
    init();
    if (lang.value) {
        document.querySelector(".circle-banner").classList.add(lang.value)
    }
})

const backgroundStyle = {
    height: '500px',
    backgroundImage: !isMobile() ? `url(${MainBanner})` : `url(${MainBannerMobile})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
}

const goToAppointment = () => {
    location.href = "/appointments?lang=" + lang.value + "#request-an-appointment";
}

const callModal = () => {
    store.dispatch("updateAppMessage", { msg: "Thank you for sharing your information. We will reach out to you shortly. Please check your phone message and email for further communication!", type: "success" })
}

</script>


<style lang="css" scoped>
.circle-banner {
    margin-top: -17%;
    margin-left: 17%;
    width: 320px;
    height: 320px;
    /* background-color: #EBE7E4; */
        background-color: #fff;
        border: 0.25px solid rgb(205, 205, 205);
    color: black;
    /* font-weight: 900; */
    font-size: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    letter-spacing: 0.07rem;
}

.circle-banner.es .n-text {
    font-size: 14px;
}
#map {
    width: 100%;
    height: 480px;
    z-index: 98;
}
@media(max-width: 600px) {
    .circle-banner {
        margin: 0 auto;
        margin-top: -70%;
    }
}
</style>