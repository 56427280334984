import { toQueryParams } from '../utils';
import http from './http.service';

const ApplicationService = {
    async getApplications(pageNumber, searchTerm, filters) {
        try {
            let response;
            if (searchTerm) {
                response = await http.get(`/i693-application/?page=${pageNumber}&search=${searchTerm}&${toQueryParams(filters)}`);
            } else {
                response = await http.get(`/i693-application/?page=${pageNumber}&${toQueryParams(filters)}`);
            }

            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            return [];
        }

    },
    async fetchFile(uuid, fileType) {
        try {
            const response = await http.get(`/i693-application/${uuid}/file/?file-type=${fileType}`);
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            return [];
        }
    },
    async fetchApplication(uuid, templateId, appointmentDate) {
        try {
            const response = await http.get(`/i693-application/${uuid}/file/i693/pdf/?office=${templateId}&appointment_date=${appointmentDate}`);
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            return {};
        }
    },

    async fetchReceipt(uuid, templateId, appointmentDate) {
        try {
            const response = await http.get(`/i693-application/${uuid}/receipt?office=${templateId}&appointment_date=${appointmentDate}`, { responseType: "blob" });
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            return {};
        }
    },

    async fetchCustomReceipt(data) {
        try {
            const response = await http.get(`/i693-application/custom/receipt?office=${data.templateId}&appointment_date=${data.appointmentDate}&first_name=${data.firstName}&last_name=${data.lastName}&middle_name=${data.middleName}&email=${data.email}`, { responseType: "blob" });
            if (response.status === 200) {

                return response.data;
            }
        } catch (error) {
            return {};
        }
    },

    async updateApplicantDetails(uuid, data) {
        try {
            const response = await http.patch(`/i693-application/${uuid}/`, JSON.stringify(data))
            if (response.status === 200) {
                return response.data
            }
        } catch (error) {
            return {}
        }

        return {}
    }
}

export default ApplicationService;