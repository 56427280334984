// auth.service.ts
import {useRouter} from "vue-router";
import {useStore} from "vuex";

const store = useStore();
const router = useRouter();

const handle401Error = () => {
  // Perform logout or any other required actions
//   store.dispatch("logout"); // Dispatch a Vuex action to handle logout
  // Redirect to login page
  localStorage.removeItem("token");
  location.href = `${import.meta.env.VITE_AUTH_APP}/auth/login`;
  // router.push({ name: "auth" });
};

const handle403Error = () => {
  // Perform logout or any other required actions
//   store.dispatch("logout"); // Dispatch a Vuex action to handle logout
  // Redirect to login page
  localStorage.removeItem("token");
  location.href = "/login?lang=en";
  // router.push({ name: "auth" });
};

export default {
  handle401Error,
  handle403Error
};
