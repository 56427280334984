import { toQueryParams } from '../utils';
import http from './http.service';

const UserService = {
    async getProfile() {
        try {
            const response = await http.get("/users/");

            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            return {};
        }

    },
    async getDashboardFeed() {
        try {
            const response = await http.get("/users/dashboard/");

            if (response.status === 200) {
                return response.data.data;
            }
        } catch (error) {
            return {};
        }

    },
}

export default UserService;