<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <n-message-provider>
      <n-modal-provider>
        <n-spin :show="isLoading">
          <n-alert :show-icon="false" class="top-banner">
            <n-text strong>I-693 CIVIL SURGEON - BAY AREA & THE VALLEY</n-text>
          </n-alert>
          <div class="top-header" v-if="vLocation.indexOf('admin') === -1">
            <n-grid cols="9" item-responsive>
              <n-gi span="0 600:2">

              </n-gi>
              <n-gi span="3 600:5">
                <n-space :justify="isMobile() ? 'start': 'center'">
                  <n-menu :options="menuOptions" mode="horizontal" responsive :collapsed-width="64"
                    :collapsed-icon-size="22" class="regular-font">
                    <template #collapsed-icon>
                      <n-icon>
                        <MenuSharp />
                      </n-icon>
                    </template>
                  </n-menu>
                </n-space>
              </n-gi>
              <n-gi span="3 600:0">
                <!-- <n-space justify="center">
                  <n-image width="60" height="80"
                    src="https://img1.wsimg.com/isteam/ip/ec55009f-efc5-4b22-b395-d29a537c3ba5/DSC_6633-HDR-3b03749.jpg/:/rs=w:143,h:200,cg:true,m/cr=w:143,h:200/qt=q:95"></n-image>
                </n-space> -->
              </n-gi>
              <n-gi span="0 600:2">
                <n-space justify="right" :size="[12, 0]">
                  <div class="elfsight-app-6ea7375b-08c8-4f5c-b7be-a07602f67912" data-elfsight-app-lazy></div>
                  <!-- <n-popover>
                    <n-list class="notranslate narrow language-list">
                      <n-list-item>
                        <n-button text @click="translatePage('en')"
                          class="lang-selector-text regular-font">English</n-button>
                      </n-list-item>
                      <n-list-item>
                        <n-button text @click="translatePage('es')"
                          class="lang-selector-text regular-font">Espaniol</n-button>
                      </n-list-item>
                      <n-list-item>
                        <n-button text @click="translatePage('ps')"
                          class="lang-selector-text regular-font">Pashto</n-button>
                      </n-list-item>
                      <n-list-item>
                        <n-button text @click="translatePage('zh-CN')"
                          class="lang-selector-text regular-font">Chinese</n-button>
                      </n-list-item>
                      <n-list-item>
                        <n-button text @click="translatePage('pa')"
                          class="lang-selector-text regular-font">Punjabi</n-button>
                      </n-list-item>
                      <n-list-item>
                        <n-button text @click="translatePage('ar')"
                          class="lang-selector-text regular-font">Arabic</n-button>
                      </n-list-item>
                    </n-list>
                    <template #trigger>
                      <n-button text>
                        <template #icon>
                          <n-icon size="20">
                            <Language />
                          </n-icon>
                        </template>
                        Translate
                      </n-button>
                    </template>
                  </n-popover> -->
                </n-space>
              </n-gi>
              <n-gi span="3 600:0" style="text-align: right;">
                <n-space justify="right" :size="[5, 0]" class="notranslate">
                  <div class="elfsight-app-6ea7375b-08c8-4f5c-b7be-a07602f67912" data-elfsight-app-lazy></div>
                </n-space>
              </n-gi>
            </n-grid>
          </div>
          <div :class="vLocation === '/' ? 'logo': 'logo small'" v-if="vLocation.indexOf('/admin') === -1">
            <n-image :preview-disabled="true"
              src="https://img1.wsimg.com/isteam/ip/ec55009f-efc5-4b22-b395-d29a537c3ba5/DSC_6633-HDR-3b03749.jpg/:/rs=w:143,h:200,cg:true,m/cr=w:143,h:200/qt=q:95"></n-image>
          </div>
          <n-layout style="">
            <n-layout-content style="overflow-x: hidden !important;">
              <BaseView />
            </n-layout-content>
          </n-layout>
        </n-spin>
      </n-modal-provider>
    </n-message-provider>
  </n-config-provider>
</template>

<script setup lang="js">
import { computed, h, nextTick, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

import posthog from 'posthog-js';
import { NIcon } from "naive-ui";
import { Home24Filled, BriefcaseMedical24Filled, BroadActivityFeed24Filled, CalendarLtr24Filled, QuestionCircle24Filled } from "@vicons/fluent";
import { Language } from "@vicons/fa";
import { MenuSharp } from "@vicons/material";
import { MenuOutline } from "@vicons/ionicons5";

import { isMobile, getCookie, getQueryParams } from "@/utils";
import BaseView from "./views/BaseView.vue";

const store = useStore();
const route = useRoute();

const isLoading = computed(() => store.getters.isLoading);
const vLocation = ref(location.pathname);
const isMobileView = ref(false);
const logoTop = ref();
const currentLanguage = ref("en");

const themeOverrides = {
  "common": {
    "primaryColor": "#000",
    "primaryColorHover": "#4B4A4BFF",
    "primaryColorPressed": "#000000FF",
    "primaryColorSuppl": "#4B4A4BFF"
  },
  "Button": {
    "textColorHover": "#6C1885FF"
  }
}


const renderIcon = (icon) => {
  return () => h(NIcon, null, { default: () => h(icon) })
}

const menuOptions = [
  {
    label: () => h(
      'a',
      {
        href: '/?lang=' + currentLanguage.value,
        target: '_self',
        rel: 'noopenner noreferrer'
      },
      'HOME'
    ),
    key: "home",
    // icon: renderIcon(Home24Filled)
  },
  {
    label: () => h(
      'a',
      {
        href: '/i-693?lang=' + currentLanguage.value,
        target: '_self',
        rel: 'noopenner noreferrer'
      },
      'I-693'
    ),
    key: "i693",
    // icon: renderIcon(BriefcaseMedical24Filled)
  },
  {
    label: () => h(
      'a',
      {
        href: '/appointments?lang=' + currentLanguage.value,
        target: '_self',
        rel: 'noopenner noreferrer'
      },
      'APPOINTMENTS'
    ),
    key: "calendar",
    // icon: renderIcon(CalendarLtr24Filled)
  },
  {
    label: () => h(
      'a',
      {
        href: '/faqs?lang=' + currentLanguage.value,
        target: '_self',
        rel: 'noopenner noreferrer'
      },
      'FAQS'
    ),
    key: "faqs",
    // icon: renderIcon(QuestionCircle24Filled)
  },
]

// const isAuthenticated = computed(() => store.getters.isAuthenticated)

onMounted(() => {
  const queryParams = getQueryParams();
  const lang = queryParams.lang;

  if (!lang) {
    currentLanguage.value = JSON.parse(localStorage.getItem("WebsiteTranslator.language.6ea7375b-08c8-4f5c-b7be-a07602f67912")).value ?? "en";
  } else {
    currentLanguage.value = lang;
  }
  store.dispatch("updateCurrentLanguage", currentLanguage.value);
  localStorage.setItem("currentLanguage", currentLanguage.value);
  vLocation.value = location.pathname;
  isMobileView.value = window.innerWidth <= 600;
  const logo = document.querySelector('.logo');

  if (vLocation.value !== "/") {
    if (logo)
      logo.classList.add('smalli');
  }

  window.addEventListener('scroll', function () {
    // const topHeader = document.querySelector(".top-header");

    // if (window.scrollY > 50) {
    //   topHeader.style.paddingTop = "1rem";
    //   topHeader.style.paddingBottom = "1rem";
    // } else {
    //   if (isMobile()) {
    //     topHeader.style.paddingTop = "1rem";
    //     topHeader.style.paddingBottom = "1rem";
    //   } else {
    //     topHeader.style.paddingTop = "2.4rem";
    //     topHeader.style.paddingBottom = "2.3rem";
    //   }
    // }
    if (logo) {
    if (vLocation.value === "/") {
      const logo = document.querySelector('.logo');

      if (window.scrollY > 50) {  // Adjust this value to the scroll position you prefer
        logo.classList.add('small');
        if (isMobile()) {
          logo.style.top = "-20px";
        } else {
          logo.style.top = "-65px";
        }
      } else {
        logo.removeAttribute('style');
        logo.classList.remove('small');
        logo.style.top = `${logoTop.value}px`
      }
    } else {
      if (window.scrollY > 50) {  // Adjust this value to the scroll position you prefer
        logo.removeAttribute('style');
        logo.classList.remove('smalli');
        if (isMobile()) {
          logo.style.top = "-20px";
        } else {
          logo.style.top = "-65px";
        }
      } else {
        logo.removeAttribute('style');
        logo.classList.add('smalli');
        logo.style.top = `${logoTop.value}px`
      }
    }
    }
  });

  nextTick(() => {
    for (const element of document.getElementsByClassName("n-menu-item-content-header")) {
      if (element.innerHTML === "···<!---->") {
        // element.innerHTML = "☰"
        element.innerHTML = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 6h18M3 12h18M3 18h18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`
      }

    }


    const topBanner = document.querySelector('.top-banner');
    const height = topBanner ? topBanner.offsetHeight : 0;

    const logo = document.querySelector('.logo');
    if (logo) {

      logoTop.value = height - 20;
      logo.style.top = `${logoTop.value}px`;

      logo.style.display = "none";
    }
    setTimeout(() => {
      window.scrollBy({ top: 5 })
      window.scrollBy({ top: -5 })
      // logo.style.display = "block";
    }, 1020);

  });

  setTimeout(() => {
    const topBanner = document.querySelector('.top-banner');
    const height = topBanner ? topBanner.offsetHeight : 0;

    const logo = document.querySelector('.logo');
    if (logo) {
      if (vLocation.value === "/") {
      if (isMobile()) {
        logoTop.value = height + 20;
      } else {
        logoTop.value = height + 10;
      }
    } else {
      if (isMobile()) {
        logoTop.value = height - 20;
      } else {
        logoTop.value = height - 35;
      }
    }
    logo.style.top = `${logoTop.value}px`;
    }
  }, 1000);

  if (!import.meta.env.VITE_DEBUG) {
    posthog.init(import.meta.env.VITE_POSTHOG_KEY, { api_host: 'https://us.i.posthog.com', person_profiles: 'identified_only' })
  }

  // console.log("isAuthenticated.value: ", isAuthenticated.value);
  // if (isAuthenticated.value) {
  //   menuOptions.push(
  //     {
  //       label: () => h(
  //         'a',
  //         {
  //           href: '/logout',
  //           target: '_self',
  //           rel: 'noopenner noreferrer'
  //         },
  //         'Logout'
  //       ),
  //       key: "logout",
  //       // icon: renderIcon(QuestionCircle24Filled)
  //     },
  //   )
  // }
})

</script>


<style>
.lang-selector-text {
  font-size: 12px;
  /* text-decoration: underline; */
  cursor: pointer;
}

.logo {
  text-align: left;
  position: fixed;
  /* top: 60px; */
  left: 30px;
  z-index: 102;
  transition: transform 0.5s ease, width 0.5s ease, height 0.5s ease;
}

.logo img {
  width: 100%;
  /* Ensure the image is responsive */
}

.logo.small {
  text-align: left;
  transform: scale(0.3);
  position: fixed;
  /* top: -60px; */
  /* Resize the logo to 20% of its original size */
}

.logo.smalli {
  text-align: left;
  transform: scale(0.5);
  position: fixed;
  /* top: 1%; */
  /* Resize the logo to 20% of its original size */
}

.top-header {
  position: sticky;
  background-color: rgba(255, 255, 255, 0.944);
  padding-top: 1.4rem;
  padding-bottom: 1.3rem;
  top: 0;
  z-index: 101;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #e3e3e385;
}

.top-banner {
  text-align: center;
  background-color: #EBE7E4;
  border: none;
  line-height: 10px;
  padding-top: 5px;
}

.top-banner .n-text {
  font-size: 24px;
}

.language-list .n-list-item:hover {
  background-color: #EEECE8
}

@media(max-width: 600px) {
  .top-header {
    position: sticky;
    background-color: rgba(255, 255, 255, 0.944);
      padding-top: 1rem;
      padding-bottom: 1rem;
      top: 0;
      z-index: 101;
      padding-left: 20px;
      padding-right: 20px;
      border: 1px solid #e3e3e385;
    }
    
    .logo {
      text-align: left;
      position: fixed;
      /* top: 100px; */
      left: 35%;
      z-index: 102;
      transition: transform 0.5s ease, width 0.5s ease, height 0.5s ease;
    }
    
    .logo .n-image img {
      width: 85.5px;
      height: 120px;
      /* Ensure the image is responsive */
    }
    
    .logo.small {
      text-align: left;
      transform: scale(0.5);
      position: fixed;
      /* top: -20px; */
      /* Resize the logo to 20% of its original size */
    }
    
    .logo.smalli {
      text-align: left;
      transform: scale(0.5);
      position: fixed;
      /* top: 65px; */
      /* Resize the logo to 20% of its original size */
    }

  .top-banner {
    font-size: 12px;
    text-align: center;
    background-color: #EBE7E4;
    border: none;
    line-height: 25px;
  }

  .top-banner .n-text {
    font-size: 22px;
  }
}
</style>
