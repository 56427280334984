<template>
    <n-form class="i-693-form" ref="formRef" :model="form" :rules="rules">
        <n-card title="I693 FORM DATA" class="i-693-form-card main-card" header-class="main-card-header"
            content-class="main-card-content">
            <n-space vertical justify="start" style="text-align: left; padding-top: 2rem; padding-bottom: 1rem;">
                <n-text strong>Please Fill These out ASAP Accurately and Submit.</n-text>
                <n-text strong>FILL THESE ATTENTIVELY as these will go on your Immigration form !!!</n-text>
            </n-space>
            <n-divider></n-divider>
            <n-grid cols="3" item-responsive class="main-grid" x-gap="20" style="text-align: left">
                <n-gi span="3 600:1">
                    <n-form-item label="LAST NAME" path="lastName">
                        <n-input v-model:value="form.lastName" placeholder="Last Name" class="block-case"></n-input>
                    </n-form-item>
                </n-gi>
                <n-gi span="3 600:1">
                    <n-form-item label="FIRST NAME" path="firstName">
                        <n-input v-model:value="form.firstName" placeholder="First Name" class="block-case"></n-input>
                    </n-form-item>
                </n-gi>
                <n-gi span="3 600:1">
                    <n-form-item label="MIDDLE NAME">
                        <n-input v-model:value="form.middleName" placeholder="Middle Name" class="block-case"></n-input>
                    </n-form-item>
                </n-gi>
            </n-grid>
            <n-grid cols="3" item-responsive class="main-grid" x-gap="20" style="text-align: left">
                <n-gi span="3 600:1">
                    <n-form-item label="A-Number(if any)" path="aNumber">
                        <n-input v-model:value="form.aNumber" :show-button="false" placeholder="Number"
                            class="block-case" @update:value="handleANumberChange" maxlength="9">
                            <template #prefix>A-</template>
                        </n-input>
                    </n-form-item>
                </n-gi>
                <n-gi span="3 600:1">
                    <n-form-item label="Gender" path="gender">
                        <n-radio-group v-model:value="form.gender" name="radiobuttongroup1">
                            <n-radio-button value="male">Male</n-radio-button>
                            <n-radio-button value="female">Female</n-radio-button>
                            <!-- <n-radio-button value="other">Other</n-radio-button> -->
                        </n-radio-group>
                    </n-form-item>
                </n-gi>
                <n-gi span="3 600:1">
                    <n-form-item label="Date Of Birth" path="dob">
                        <n-grid cols="12" item-responsive x-gap="5">
                            <n-gi span="5">
                                <n-select v-model:value="form.dob.month" :options="monthOptions"
                                    placeholder="Month"></n-select>
                            </n-gi>
                            <n-gi span="3">
                                <n-select v-model:value="form.dob.day" :options="dayOptions" placeholder="Day"
                                    style="max-width: 80px;"></n-select>
                            </n-gi>
                            <n-gi span="4">

                                <n-select v-model:value="form.dob.year" :options="yearOptions"
                                    placeholder="Year"></n-select>
                            </n-gi>
                        </n-grid>
                    </n-form-item>
                </n-gi>
            </n-grid>
            <n-grid cols="3" item-responsive class="main-grid" x-gap="20" style="text-align: left">
                <n-gi span="3 600:2">
                    <n-form-item label="City of Birth (as per Passport)" path="cityOfBirth">
                        <n-input v-model:value="form.cityOfBirth" placeholder="As mentioned on Passport"></n-input>
                    </n-form-item>
                </n-gi>
                <n-gi span="3 600:1">
                    <n-form-item label="Country of Birth (as per Passport)" path="countryOfBirth">
                        <!-- <n-input v-model:value="form.countryOfBirth" placeholder="As mentioned on Passport"></n-input> -->
                        <n-select v-model:value="form.countryOfBirth" :options="countryOptions" filterable
                            placeholder="Choose country" autocomplete="country" name="country" />
                    </n-form-item>
                </n-gi>
            </n-grid>
            <n-divider></n-divider>
            <n-p>Current Address for Immigration Form</n-p>
            <n-grid cols="3" item-responsive class="main-grid" x-gap="20" style="text-align: left">
                <n-gi span="3 600:2">
                    <n-form-item label="Street Number and Name" path="address">
                        <n-input v-model:value="form.address" placeholder="address" autocomplete="address-line1"
                            name="address"></n-input>
                    </n-form-item>
                </n-gi>
                <n-gi span="3 600:1">
                    <n-grid cols="6">
                        <!-- <n-gi span="1">
                            <n-form-item label="Apt." path="">
                                <n-radio name="apt-choice" value="apt" :checked="aptChoice === 'apt'"
                                    @change="aptChoice = 'apt'"></n-radio>
                            </n-form-item>
                        </n-gi>
                        <n-gi span="1">
                            <n-form-item label="Ste." path="">
                                <n-radio name="apt-choice" value="apt" :checked="aptChoice === 'ste'"
                                    @change="aptChoice = 'ste'"></n-radio>
                            </n-form-item>
                        </n-gi>
                        <n-gi span="1">
                            <n-form-item label="Flr." path="">
                                <n-radio name="apt-choice" value="flr" :checked="aptChoice === 'flr'"
                                    @change="aptChoice = 'flr'"></n-radio>
                            </n-form-item>
                        </n-gi> -->
                        <n-gi span="6">
                            <n-form-item label="Apartment No" path="apartmentNumber">
                                <n-input v-model:value="form.apartmentNumber" placeholder=""></n-input>
                            </n-form-item>
                        </n-gi>
                    </n-grid>
                </n-gi>
            </n-grid>
            <n-grid cols="3" item-responsive class="main-grid" x-gap="20" style="text-align: left">
                <n-gi span="3 600:1">
                    <n-form-item label="City" path="city">
                        <n-input v-model:value="form.city" placeholder="City"></n-input>
                    </n-form-item>
                </n-gi>
                <n-gi span="3 600:1">
                    <n-form-item label="State" path="state">
                        <n-select v-model:value="form.state" :options="stateOptions"
                            placeholder="Choose state"></n-select>
                    </n-form-item>
                </n-gi>
                <n-gi span="3 600:1">
                    <n-form-item label="Zipcode" path="zipcode">
                        <n-input v-model:value="form.zipcode" placeholder="zipcode"></n-input>
                    </n-form-item>
                </n-gi>
            </n-grid>
            <n-grid cols="3" item-responsive class="main-grid" x-gap="20" style="text-align: left">
                <n-gi span="3 600:1">
                    <n-form-item label="Mobile Phone" path="phoneNumber">
                        <n-input v-model:value="form.phoneNumber" :max="9999999999" :show-button="false"
                            style="width: 100%" @update:value="handlePhoneNumberChange" maxlength="10"
                            placeholder=""></n-input>
                    </n-form-item>
                </n-gi>
                <n-gi span="3 600:2">
                    <n-form-item label="Email" path="email">
                        <n-input v-model:value="form.email" placeholder="For communications"></n-input>
                    </n-form-item>
                </n-gi>
            </n-grid>
            <n-divider></n-divider>
            <n-p>Photo ID</n-p>
            <n-grid cols="6" item-responsive class="main-grid" x-gap="20" style="text-align: left">
                <n-gi span="6 600:1">
                    <n-form-item label="Front">
                        <n-upload v-model:file-list="frontFileList" :max="1" name="front" @change="handleFrontUpload"
                            @update:file-list="" :show-file-list="false" accept=".png,.jpg,.pdf">
                            <n-upload-dragger>
                                <n-icon :size="30">
                                    <UploadFilled />
                                </n-icon>
                                <br />
                                <n-text class="mini-text">Photo or PDF only</n-text>
                            </n-upload-dragger>
                        </n-upload>
                    </n-form-item>
                    <n-text v-if="frontFileList.length > 0">Front</n-text>
                    <n-card :bordered="false" v-if="frontFileList.length > 0">
                        <template #cover>
                            <n-image :src="frontFile"></n-image>
                        </template>
                        <n-space justify="center">
                            <n-button type="secondary" @click="deleteFrontFile">Delete</n-button>
                        </n-space>
                    </n-card>
                </n-gi>
                <n-gi span="6 600:1">
                    <n-form-item label="Back">
                        <n-upload v-model:file-list="backFileList" :max="1" name="back" @change="handleBackUpload"
                            @update:file-list="" :show-file-list="false">
                            <n-upload-dragger>
                                <n-icon :size="30">
                                    <UploadFilled />
                                </n-icon>
                                <br />
                                <n-text class="mini-text">Photo or PDF only</n-text>
                            </n-upload-dragger>
                        </n-upload>
                    </n-form-item>
                    <n-text v-if="backFileList.length > 0">Back</n-text>
                    <n-card :bordered="false" v-if="backFileList.length > 0">
                        <template #cover>
                            <n-image :src="backFile"></n-image>
                        </template>
                        <n-space justify="center">
                            <n-button type="secondary" @click="deleteBackFile">Delete</n-button>
                        </n-space>
                    </n-card>
                </n-gi>
                <n-gi span="6 600:4">
                    <n-grid cols="3" item-responsive>
                        <n-gi span="3 600:1">
                            <n-form-item label="Photo ID Type" path="photoIdType">
                                <n-select v-model:value="form.photoIdType" :options="photoIdTypes" />
                            </n-form-item>
                        </n-gi>
                        <n-gi span="3 600:1" v-if="form.photoIdType === 'passport'">
                            <n-form-item label="Nationality" path="photoIdType">
                                <n-select v-model:value="nationality" :options="countryOptions" filterable />
                            </n-form-item>
                        </n-gi>
                        <n-gi span="3 600:1"
                            v-if="form.photoIdType === 'drivers-license' || form.photoIdType === 'state-id'">
                            <n-form-item label="Issued in" path="photoIdType">
                                <n-select v-model:value="licenseState" :options="stateOptions" />
                            </n-form-item>
                        </n-gi>
                        <n-gi span="3 600:1">
                            <n-form-item label="Photo ID Number:" path="photoIdNumber">
                                <n-input v-model:value="form.photoIdNumber" />
                            </n-form-item>
                        </n-gi>
                    </n-grid>
                </n-gi>
            </n-grid>
            <n-divider></n-divider>
            <n-p>
                <n-text style="font-size: 15px;">By signing below you agree to our terms & conditions, & agree to share
                    uploaded documents with
                    Ask For
                    Cure LLC, receive emails and texts from our team in regards to your immigration medical exam and
                    relevant medical records among other details. <sup style="color: #D53353; font-weight: 600"
                        v-if="!signed">*</sup></n-text></n-p>
            <n-grid cols="6" item-responsive class="main-grid" x-gap="20" style="text-align: left">
                <n-gi span="6 600:2" id="signature-gi">
                    <n-space vertical justify="center" :size="[0, 0]">
                        <canvas class="signature-canvas" />
                        <n-space justify="end">
                            <n-button secondary size="small" @click="clearSign">Clear</n-button>
                        </n-space>
                    </n-space>
                </n-gi>
            </n-grid>
            <template #footer>
                <n-space justify="right" style="padding-top: 1rem;">
                    <n-button type="primary" @click="save">Save and Upload</n-button>
                </n-space>
            </template>
        </n-card>
    </n-form>
</template>
<script setup lang="js">
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

import { FormatColorResetSharp, SingleBedOutlined, UploadFilled } from "@vicons/material";
import SignaturePad from "signature_pad";
import dayjs from "dayjs";

import MiscService from "../services/misc.service";

const store = useStore();

const signaturePad = ref();
const signed = ref(false);
const formRef = ref(null);
const frontFileList = ref([]);
const backFileList = ref([]);
const frontFile = ref();
const backFile = ref();
const aptChoice = ref();
const nationality = ref();
const licenseState = ref();

const form = ref({
    lastName: "",
    firstName: "",
    middleName: "",
    aNumber: "",
    gender: "",
    dob: { month: null, day: null, year: null },
    phoneNumber: "",
    email: "",
    cityOfBirth: "",
    countryOfBirth: "",
    apartmentNumber: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    photoIdType: "",
    photoIdNumber: "",
});

const photoIdTypes = ref([
    { label: "Passport", value: "passport" },
    { label: "Driver's License", value: "drivers-license" },
    { label: "Work Permit", value: "work-permit" },
    { label: "State ID", value: "state-id" },
])

const monthOptions = ref([
    { label: 'January', value: '1' },
    { label: 'February', value: '2' },
    { label: 'March', value: '3' },
    { label: 'April', value: '4' },
    { label: 'May', value: '5' },
    { label: 'June', value: '6' },
    { label: 'July', value: '7' },
    { label: 'August', value: '8' },
    { label: 'September', value: '9' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' },
])

const countryOptions = [
    { 'label': 'Afghanistan', 'value': 'Afghanistan' },
    { 'label': 'Albania', 'value': 'Albania' },
    { 'label': 'Algeria', 'value': 'Algeria' },
    { 'label': 'Andorra', 'value': 'Andorra' },
    { 'label': 'Angola', 'value': 'Angola' },
    { 'label': 'Antigua and Barbuda', 'value': 'Antigua and Barbuda' },
    { 'label': 'Argentina', 'value': 'Argentina' },
    { 'label': 'Armenia', 'value': 'Armenia' },
    { 'label': 'Australia', 'value': 'Australia' },
    { 'label': 'Austria', 'value': 'Austria' },
    { 'label': 'Azerbaijan', 'value': 'Azerbaijan' },
    { 'label': 'The Bahamas', 'value': 'The Bahamas' },
    { 'label': 'Bahrain', 'value': 'Bahrain' },
    { 'label': 'Bangladesh', 'value': 'Bangladesh' },
    { 'label': 'Barbados', 'value': 'Barbados' },
    { 'label': 'Belarus', 'value': 'Belarus' },
    { 'label': 'Belgium', 'value': 'Belgium' },
    { 'label': 'Belize', 'value': 'Belize' },
    { 'label': 'Benin', 'value': 'Benin' },
    { 'label': 'Bhutan', 'value': 'Bhutan' },
    { 'label': 'Bolivia', 'value': 'Bolivia' },
    { 'label': 'Bosnia and Herzegovina', 'value': 'Bosnia and Herzegovina' },
    { 'label': 'Botswana', 'value': 'Botswana' },
    { 'label': 'Brazil', 'value': 'Brazil' },
    { 'label': 'Brunei', 'value': 'Brunei' },
    { 'label': 'Bulgaria', 'value': 'Bulgaria' },
    { 'label': 'Burkina Faso', 'value': 'Burkina Faso' },
    { 'label': 'Burundi', 'value': 'Burundi' },
    { 'label': 'Cambodia', 'value': 'Cambodia' },
    { 'label': 'Cameroon', 'value': 'Cameroon' },
    { 'label': 'Canada', 'value': 'Canada' },
    { 'label': 'Cape Verde', 'value': 'Cape Verde' },
    { 'label': 'Central African Republic', 'value': 'Central African Republic' },
    { 'label': 'Chad', 'value': 'Chad' },
    { 'label': 'Chile', 'value': 'Chile' },
    { 'label': 'China', 'value': 'China' },
    { 'label': 'Colombia', 'value': 'Colombia' },
    { 'label': 'Comoros', 'value': 'Comoros' },
    { 'label': 'Republic of the Congo', 'value': 'Republic of the Congo' },
    { 'label': 'Congo, Democratic Republic of the', 'value': 'Congo, Democratic Republic of the' },
    { 'label': 'Costa Rica', 'value': 'Costa Rica' },
    { 'label': "Cote d'Ivoire", 'value': "Cote d'Ivoire" },
    { 'label': 'Croatia', 'value': 'Croatia' },
    { 'label': 'Cuba', 'value': 'Cuba' },
    { 'label': 'Cyprus', 'value': 'Cyprus' },
    { 'label': 'Czech Republic', 'value': 'Czech Republic' },
    { 'label': 'Denmark', 'value': 'Denmark' },
    { 'label': 'Djibouti', 'value': 'Djibouti' },
    { 'label': 'Dominica', 'value': 'Dominica' },
    { 'label': 'Dominican Republic', 'value': 'Dominican Republic' },
    { 'label': 'East Timor (Timor-Leste)', 'value': 'East Timor (Timor-Leste)' },
    { 'label': 'Ecuador', 'value': 'Ecuador' },
    { 'label': 'Egypt', 'value': 'Egypt' },
    { 'label': 'El Salvador', 'value': 'El Salvador' },
    { 'label': 'Equatorial Guinea', 'value': 'Equatorial Guinea' },
    { 'label': 'Eritrea', 'value': 'Eritrea' },
    { 'label': 'Estonia', 'value': 'Estonia' },
    { 'label': 'Ethiopia', 'value': 'Ethiopia' },
    { 'label': 'Fiji', 'value': 'Fiji' },
    { 'label': 'Finland', 'value': 'Finland' },
    { 'label': 'France', 'value': 'France' },
    { 'label': 'Gabon', 'value': 'Gabon' },
    { 'label': 'The Gambia', 'value': 'The Gambia' },
    { 'label': 'Georgia', 'value': 'Georgia' },
    { 'label': 'Germany', 'value': 'Germany' },
    { 'label': 'Ghana', 'value': 'Ghana' },
    { 'label': 'Greece', 'value': 'Greece' },
    { 'label': 'Grenada', 'value': 'Grenada' },
    { 'label': 'Guatemala', 'value': 'Guatemala' },
    { 'label': 'Guinea', 'value': 'Guinea' },
    { 'label': 'Guinea-Bissau', 'value': 'Guinea-Bissau' },
    { 'label': 'Guyana', 'value': 'Guyana' },
    { 'label': 'Haiti', 'value': 'Haiti' },
    { 'label': 'Honduras', 'value': 'Honduras' },
    { 'label': 'Hungary', 'value': 'Hungary' },
    { 'label': 'Iceland', 'value': 'Iceland' },
    { 'label': 'India', 'value': 'India' },
    { 'label': 'Indonesia', 'value': 'Indonesia' },
    { 'label': 'Iran', 'value': 'Iran' },
    { 'label': 'Iraq', 'value': 'Iraq' },
    { 'label': 'Ireland', 'value': 'Ireland' },
    { 'label': 'Israel', 'value': 'Israel' },
    { 'label': 'Italy', 'value': 'Italy' },
    { 'label': 'Jamaica', 'value': 'Jamaica' },
    { 'label': 'Japan', 'value': 'Japan' },
    { 'label': 'Jordan', 'value': 'Jordan' },
    { 'label': 'Kazakhstan', 'value': 'Kazakhstan' },
    { 'label': 'Kenya', 'value': 'Kenya' },
    { 'label': 'Kiribati', 'value': 'Kiribati' },
    { 'label': 'Korea, North', 'value': 'Korea, North' },
    { 'label': 'Korea, South', 'value': 'Korea, South' },
    { 'label': 'Kosovo', 'value': 'Kosovo' },
    { 'label': 'Kuwait', 'value': 'Kuwait' },
    { 'label': 'Kyrgyzstan', 'value': 'Kyrgyzstan' },
    { 'label': 'Laos', 'value': 'Laos' },
    { 'label': 'Latvia', 'value': 'Latvia' },
    { 'label': 'Lebanon', 'value': 'Lebanon' },
    { 'label': 'Lesotho', 'value': 'Lesotho' },
    { 'label': 'Liberia', 'value': 'Liberia' },
    { 'label': 'Libya', 'value': 'Libya' },
    { 'label': 'Liechtenstein', 'value': 'Liechtenstein' },
    { 'label': 'Lithuania', 'value': 'Lithuania' },
    { 'label': 'Luxembourg', 'value': 'Luxembourg' },
    { 'label': 'Macedonia', 'value': 'Macedonia' },
    { 'label': 'Madagascar', 'value': 'Madagascar' },
    { 'label': 'Malawi', 'value': 'Malawi' },
    { 'label': 'Malaysia', 'value': 'Malaysia' },
    { 'label': 'Maldives', 'value': 'Maldives' },
    { 'label': 'Mali', 'value': 'Mali' },
    { 'label': 'Malta', 'value': 'Malta' },
    { 'label': 'Marshall Islands', 'value': 'Marshall Islands' },
    { 'label': 'Mauritania', 'value': 'Mauritania' },
    { 'label': 'Mauritius', 'value': 'Mauritius' },
    { 'label': 'Mexico', 'value': 'Mexico' },
    { 'label': 'Micronesia, Federated States of', 'value': 'Micronesia, Federated States of' },
    { 'label': 'Moldova', 'value': 'Moldova' },
    { 'label': 'Monaco', 'value': 'Monaco' },
    { 'label': 'Mongolia', 'value': 'Mongolia' },
    { 'label': 'Montenegro', 'value': 'Montenegro' },
    { 'label': 'Morocco', 'value': 'Morocco' },
    { 'label': 'Mozambique', 'value': 'Mozambique' },
    { 'label': 'Myanmar (Burma)', 'value': 'Myanmar (Burma)' },
    { 'label': 'Namibia', 'value': 'Namibia' },
    { 'label': 'Nauru', 'value': 'Nauru' },
    { 'label': 'Nepal', 'value': 'Nepal' },
    { 'label': 'Netherlands', 'value': 'Netherlands' },
    { 'label': 'New Zealand', 'value': 'New Zealand' },
    { 'label': 'Nicaragua', 'value': 'Nicaragua' },
    { 'label': 'Niger', 'value': 'Niger' },
    { 'label': 'Nigeria', 'value': 'Nigeria' },
    { 'label': 'Norway', 'value': 'Norway' },
    { 'label': 'Oman', 'value': 'Oman' },
    { 'label': 'Pakistan', 'value': 'Pakistan' },
    { 'label': 'Palau', 'value': 'Palau' },
    { 'label': 'Palestine', 'value': 'Palestine' },
    { 'label': 'Panama', 'value': 'Panama' },
    { 'label': 'Papua New Guinea', 'value': 'Papua New Guinea' },
    { 'label': 'Paraguay', 'value': 'Paraguay' },
    { 'label': 'Peru', 'value': 'Peru' },
    { 'label': 'Philippines', 'value': 'Philippines' },
    { 'label': 'Poland', 'value': 'Poland' },
    { 'label': 'Portugal', 'value': 'Portugal' },
    { 'label': 'Qatar', 'value': 'Qatar' },
    { 'label': 'Romania', 'value': 'Romania' },
    { 'label': 'Russia', 'value': 'Russia' },
    { 'label': 'Rwanda', 'value': 'Rwanda' },
    { 'label': 'Saint Kitts and Nevis', 'value': 'Saint Kitts and Nevis' },
    { 'label': 'Saint Lucia', 'value': 'Saint Lucia' },
    { 'label': 'Saint Vincent and the Grenadines', 'value': 'Saint Vincent and the Grenadines' },
    { 'label': 'Samoa', 'value': 'Samoa' },
    { 'label': 'San Marino', 'value': 'San Marino' },
    { 'label': 'Sao Tome and Principe', 'value': 'Sao Tome and Principe' },
    { 'label': 'Saudi Arabia', 'value': 'Saudi Arabia' },
    { 'label': 'Senegal', 'value': 'Senegal' },
    { 'label': 'Serbia', 'value': 'Serbia' },
    { 'label': 'Seychelles', 'value': 'Seychelles' },
    { 'label': 'Sierra Leone', 'value': 'Sierra Leone' },
    { 'label': 'Singapore', 'value': 'Singapore' },
    { 'label': 'Slovakia', 'value': 'Slovakia' },
    { 'label': 'Slovenia', 'value': 'Slovenia' },
    { 'label': 'Solomon Islands', 'value': 'Solomon Islands' },
    { 'label': 'Somalia', 'value': 'Somalia' },
    { 'label': 'South Africa', 'value': 'South Africa' },
    { 'label': 'South Sudan', 'value': 'South Sudan' },
    { 'label': 'Spain', 'value': 'Spain' },
    { 'label': 'Sri Lanka', 'value': 'Sri Lanka' },
    { 'label': 'Sudan', 'value': 'Sudan' },
    { 'label': 'Suriname', 'value': 'Suriname' },
    { 'label': 'Swaziland', 'value': 'Swaziland' },
    { 'label': 'Sweden', 'value': 'Sweden' },
    { 'label': 'Switzerland', 'value': 'Switzerland' },
    { 'label': 'Syria', 'value': 'Syria' },
    { 'label': 'Taiwan', 'value': 'Taiwan' },
    { 'label': 'Tajikistan', 'value': 'Tajikistan' },
    { 'label': 'Tanzania', 'value': 'Tanzania' },
    { 'label': 'Thailand', 'value': 'Thailand' },
    { 'label': 'Togo', 'value': 'Togo' },
    { 'label': 'Tonga', 'value': 'Tonga' },
    { 'label': 'Trinidad and Tobago', 'value': 'Trinidad and Tobago' },
    { 'label': 'Tunisia', 'value': 'Tunisia' },
    { 'label': 'Turkey', 'value': 'Turkey' },
    { 'label': 'Turkmenistan', 'value': 'Turkmenistan' },
    { 'label': 'Tuvalu', 'value': 'Tuvalu' },
    { 'label': 'Uganda', 'value': 'Uganda' },
    { 'label': 'Ukraine', 'value': 'Ukraine' },
    { 'label': 'United Arab Emirates', 'value': 'United Arab Emirates' },
    { 'label': 'United Kingdom', 'value': 'United Kingdom' },
    { 'label': 'United States of America', 'value': 'United States of America' },
    { 'label': 'Uruguay', 'value': 'Uruguay' },
    { 'label': 'Uzbekistan', 'value': 'Uzbekistan' },
    { 'label': 'Vanuatu', 'value': 'Vanuatu' },
    { 'label': 'Vatican City (Holy See)', 'value': 'Vatican City (Holy See)' },
    { 'label': 'Venezuela', 'value': 'Venezuela' },
    { 'label': 'Vietnam', 'value': 'Vietnam' },
    { 'label': 'Yemen', 'value': 'Yemen' },
    { 'label': 'Zambia', 'value': 'Zambia' },
    { 'label': 'Zimbabwe', 'value': 'Zimbabwe' }
]

const dayOptions = Array.from({ length: 31 }, (_, i) => ({
    label: (i + 1).toString(),
    value: i + 1,
}))

const yearOptions = Array.from({ length: 2024 - 1924 + 1 }, (_, i) => ({
    label: (2024 - i).toString(), // Display the year as a string
    value: 2024 - i,             // Store the year as a numeric value
}));

const rules = {
    lastName: [{ required: true, message: "Last Name is required", trigger: "blur" }],
    firstName: [{ required: true, message: "First Name is required", trigger: "blur" }],
    gender: [{ required: true, message: "Gender is required", trigger: "blur" }],
    cityOfBirth: [{ required: true, message: "City is required", trigger: "blur" }],
    countryOfBirth: [{ required: true, message: "Country is required", trigger: "blur" }],
    city: [{ required: true, message: "City is required", trigger: "blur" }],
    country: [{ required: true, message: "Country is required", trigger: "blur" }],
    address: [{ required: true, message: "Address is required", trigger: "blur" }],
    state: [{ required: true, message: "State is required", trigger: "blur" }],
    zipcode: [{ required: true, message: "Zipcode is required", trigger: "blur" }],
    apartmentNumber: [{ required: false, message: "This is required", trigger: "blur" }],
    photoIdType: [{ required: true, message: "This is required", trigger: "blur" }],
    photoIdNumber: [{ required: true, message: "This is required", trigger: "blur" }],
    dob: [
        {
            required: true,
            validator: (_, value) =>
                value && value.month && value.day && value.year
                    ? Promise.resolve()
                    : Promise.reject("Complete Date of Birth is required"),
            trigger: "change",
        },
    ],
    email: {
        required: true,
        trigger: "blur",
        message: "Please input a valid email id.",
        validator: (rule, value) => {
            if (!value) {
                return new Error("Email is required");
            }
            const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
            if (!emailRegex.test(value)) {
                return new Error("Please enter a valid email address");
            }
            return true; // Validation passed                
        }
    },
    phoneNumber: {
        required: true,
        trigger: "blur",
        message: "Please input a valid contact number",
        validator: (rule, value) => {
            if (!value) {
                return new Error("Please enter your contact number");
            }
            const contactRegex = /^[0-9]{10}$/;
            if (!contactRegex.test(value)) {
                return new Error("Please enter a valid contact number");
            }
            return true; // Validation passed
        }
    },
};

onMounted(() => {
    const canvas = document.querySelector('.signature-canvas');
    signaturePad.value = new SignaturePad(canvas);
    signaturePad.value.addEventListener("endStroke", () => {
        signed.value = true;
    })
})

const stateOptions = [
    { "label": "Alabama", "value": "AL" },
    { "label": "Alaska", "value": "AK" },
    { "label": "Arizona", "value": "AZ" },
    { "label": "Arkansas", "value": "AR" },
    { "label": "California", "value": "CA" },
    { "label": "Colorado", "value": "CO" },
    { "label": "Connecticut", "value": "CT" },
    { "label": "Delaware", "value": "DE" },
    { "label": "District of Columbia", "value": "DC" },
    { "label": "Florida", "value": "FL" },
    { "label": "Georgia", "value": "GA" },
    { "label": "Hawaii", "value": "HI" },
    { "label": "Idaho", "value": "ID" },
    { "label": "Illinois", "value": "IL" },
    { "label": "Indiana", "value": "IN" },
    { "label": "Iowa", "value": "IA" },
    { "label": "Kansas", "value": "KS" },
    { "label": "Kentucky", "value": "KY" },
    { "label": "Louisiana", "value": "LA" },
    { "label": "Maine", "value": "ME" },
    { "label": "Maryland", "value": "MD" },
    { "label": "Massachusetts", "value": "MA" },
    { "label": "Michigan", "value": "MI" },
    { "label": "Minnesota", "value": "MN" },
    { "label": "Mississippi", "value": "MS" },
    { "label": "Missouri", "value": "MO" },
    { "label": "Montana", "value": "MT" },
    { "label": "Nebraska", "value": "NE" },
    { "label": "Nevada", "value": "NV" },
    { "label": "New Hampshire", "value": "NH" },
    { "label": "New Jersey", "value": "NJ" },
    { "label": "New Mexico", "value": "NM" },
    { "label": "New York", "value": "NY" },
    { "label": "North Carolina", "value": "NC" },
    { "label": "North Dakota", "value": "ND" },
    { "label": "Ohio", "value": "OH" },
    { "label": "Oklahoma", "value": "OK" },
    { "label": "Oregon", "value": "OR" },
    { "label": "Pennsylvania", "value": "PA" },
    { "label": "Rhode Island", "value": "RI" },
    { "label": "South Carolina", "value": "SC" },
    { "label": "South Dakota", "value": "SD" },
    { "label": "Tennessee", "value": "TN" },
    { "label": "Texas", "value": "TX" },
    { "label": "Utah", "value": "UT" },
    { "label": "Vermont", "value": "VT" },
    { "label": "Virginia", "value": "VA" },
    { "label": "Washington", "value": "WA" },
    { "label": "West Virginia", "value": "WV" },
    { "label": "Wisconsin", "value": "WI" },
    { "label": "Wyoming", "value": "WY" }
]

const handlePhoneNumberChange = (value) => {
    // Remove non-digit characters
    const sanitizedValue = value ? value.replace(/\D+/g, '') : '';
    form.value.phoneNumber = sanitizedValue;
};

const handleANumberChange = (value) => {
    // Remove non-digit characters
    const sanitizedValue = value ? value.replace(/\D+/g, '') : '';
    form.value.aNumber = sanitizedValue;
};

const clearSign = () => {
    signaturePad.value.clear();
    signed.value = false;
}

const handleFrontUpload = (file) => {
    frontFile.value = URL.createObjectURL(file.file.file);
}

const deleteFrontFile = () => {
    frontFile.value = "";
    frontFileList.value = [];
}

const handleBackUpload = (file) => {
    backFile.value = URL.createObjectURL(file.file.file);
}

const deleteBackFile = () => {
    backFile.value = "";
    backFileList.value = [];
}

const save = () => {
    if ((frontFileList.value.length === 0) || (backFileList.value.length === 0)) {
        store.dispatch("updateAppMessage", { msg: "Please upload Front and Back images of your photo ID", type: "error" })
        return
    }
    formRef.value.validate(async (errors) => {
        if (!errors) {
            const renamedFrontFiles = frontFileList.value.map(element => {
                const file = element.file;
                const fileName = file.name.replace(/ /g, "_");
                return {
                    ...element,
                    file: new File([file], `FRONT-${fileName}`, { type: file.type }),
                };
            });

            const renamedBackFiles = backFileList.value.map(element => {
                const file = element.file;
                const fileName = file.name.replace(/ /g, "_");
                return {
                    ...element,
                    file: new File([file], `BACK-${fileName}`, { type: file.type }),
                };
            });
            form.value.files = renamedFrontFiles.concat(renamedBackFiles);
            form.value.dob = `${form.value.dob.year}-${form.value.dob.month}-${form.value.dob.day}`;

            if (form.value.photoIdType === "passport") {
                form.value.photoIdNumber = `${nationality.value}-${form.value.photoIdNumber}`
            } else if (form.value.photoIdType === "drivers-license" || form.value.photoIdType === "state-id") {
                form.value.photoIdNumber = `${licenseState.value}-${form.value.photoIdNumber}`
            }

            store.dispatch("updateIsLoading", true);
            const response = await MiscService.createI693Application(form.value);
            store.dispatch("updateIsLoading", false);
            form.value.dob = { month: null, day: null, year: null };
            if (response.uuid) {
                store.dispatch("updateAppMessage", { msg: "Applicant details were successfully submitted.", type: "success" })
                form.value.firstName = "";
                form.value.lastName = "";
                form.value.middleName = "";
                form.value.aNumber = "";
                form.value.gender = "";
                form.value.photoIdNumber = "";
                form.value.photoIdType = "";
                form.value.cityOfBirth = "";
                frontFileList.value = [];
                backFileList.value = [];
                frontFile.value = "";
                backFile.value = "";
            } else {
                store.dispatch("updateAppMessage", { msg: "Some issue. Please retry or contact us right away!", type: "error" })
            }
        }
    })
}
</script>


<style lang="css">
.signature-canvas {
    width: 100%;
    border: 1px solid var(--wheat-background-color);
}

.i-693-form .i-693-form-card.main-card .main-card-header {
    text-align: left;
    font-size: 30px;
    font-weight: 500;
    background-color: var(--wheat-background-color);
}

.i-693-form .i-693-form-card.main-card-content {
    padding-top: 1rem;
    background: white;
    background-color: white !important;
}

.i-693-form .i-693-form-card.main-card .main-card-content-header {
    width: 100%;
    background-color: var(--wheat-background-color);
}

.i-693-form .i-693-form-card .main-card-content .n-form-item .n-form-item-label {
    font-weight: 600;
}

.i-693-form .i-693-form-card .main-card-content .n-radio-button.n-radio-button--checked {
    background: black;
    color: white;
}


.i-693-form .n-grid.main-grid {
    background: white;
    width: 100%;
}

@media (max-width: 480px) {

    /* CSS for regular mobile devices */
    .signature-canvas {
        width: 200px;
        }
        }
        
        @media (max-width: 600px) {
        
            /* CSS for large phones and small tablets */
            .signature-canvas {
                width: 250px;
            }
        }
        
        @media (min-width: 601px) and (max-width: 768px) {
        
            /* CSS for tablets */
            .signature-canvas {
                width: 300px;
            }
}

@media (max-height: 500px) {
    /* CSS for folded screens (e.g., Galaxy Fold folded mode) */
}
</style>