import { decodeJwt } from 'jose';
import { createStore } from 'vuex';

import UserService from "@/services/user.service.js";

const store = createStore({
  state: {
    languagePopupDisplayed: false,
    isLoading: false,
    appMessage: null,
    appMessageType: "info",
    currentLanguage: "en",
    isAuthenticated: false,
    token: localStorage.getItem('jToken') || null,
    user: {}
  },
  mutations: {
    setLanguagePopupDisplayed(state, val) {
      state.languagePopupDisplayed = val;
    },
    setCurrentLanguage(state, lang) {
      state.currentLanguage = lang;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setAppMessage(state, message) {
      state.appMessage = message;
    },
    setAppMessageType(state, messageType) {
      state.appMessageType = messageType;
    },
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('jToken', token);
    },
    setUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    updateLoggedIn({ commit }, loggedInData) {
      commit('setToken', loggedInData.token);
    },
    updateUser({ commit }, user) {
      commit('setUser', user);
    },
    updateIsLoading({ commit }, isLoading) {
      commit("setLoading", isLoading);
    },
    updateAppMessage({ commit }, message) {
      commit(
        "setAppMessage",
        `${new Date().getUTCMilliseconds()} | ${message.msg}`
      );
      commit("setAppMessageType", message.type ?? "info");
    },
    updateCurrentLanguage({ commit }, lang) {
      commit("setCurrentLanguage", lang);
    },
    updateLanguagePopupDisplayed({ commit }, val) {
      commit("setLanguagePopupDisplayed", val);
    },
  },
  getters: {
    user: (state) => state.user,
    isLoading: (state) => state.isLoading,
    languagePopupDisplayed: (state) => state.languagePopupDisplayed,
    appMessage: (state) => state.appMessage,
    appMessageType: (state) => state.appMessageType,
    currentLanguage: (state) => state.currentLanguage,
    isAuthenticated: async (state) => {
      const token = state.token;
      if (!token) return false;

      try {
        const decoded = decodeJwt(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        return decoded.exp > currentTime; // Check if token is expired
      } catch (error) {
        console.error('Invalid JWT Token:', error);
        return false;
      }
    },
  }
})

export default store;